import styled from 'styled-components'

const BottomBarNavigationStyle = styled.div`
	--menuPadding: var(--sp7x) var(--sp7x) var(--sp13x) var(--sp7x);
	--menuItemMarginBottom: var(--sp3x);
	--accountMenuItemMarginBottom: var(--sp1-5x);
	--accountMenuSectionTitleMarginTop: var(--sp3x);
	--fullNameMarginTop: var(--sp1-5x);
	--userSectionPadding: var(--sp4x);

	position: fixed;
	display: inline-flex;
	align-items: center;
	bottom: var(--sp3x);
	left: 50%;
	transform: translateX(-50%);
	border-radius: var(--sp1x);
	z-index: 99999;

	.button-wrap {
		display: inline-flex;
		align-items: center;
		padding: var(--sp1x);
		border-radius: var(--sp1x);
		box-shadow: var(--boxShadow);
		background-color: var(--pureWhite);
	}

	.bottom-bar-first-button {
		margin-right: var(--sp1x);
	}

	.menu {
		position: fixed;
		z-index: -1;
		left: 50%;
		bottom: calc(var(--sp2x) * -1);
		width: var(--sp28x);
		transform: translateX(-50%);
		background-color: var(--pureWhite);
		padding: var(--menuPadding);
		text-align: center;
		border-radius: var(--sp2x);
		box-shadow: var(--boxShadow);

		.menu-item {
			display: block;
			margin-bottom: var(--menuItemMarginBottom);

			&.active {
				font-weight: 700 !important;
			}

			&::first-letter {
				text-transform: uppercase;
			}
		}
	}

	.menu-account {
		position: fixed;
		z-index: -1;
		left: 50%;
		bottom: calc(var(--sp2x) * -1);
		width: var(--sp32x);
		transform: translateX(-50%);

		.user {
			box-shadow: var(--boxShadow);
			background-color: var(--pureWhite);
			border-radius: var(--sp2x);
			box-shadow: var(--boxShadow);
			padding: var(--userSectionPadding);

			.full-name {
                display: flex;
				margin-top: var(--fullNameMarginTop);
			}
		}

		.routes {
			padding: var(--menuPadding);
			background-color: var(--pureWhite);
			border-radius: var(--sp2x);
			text-align: center;
			box-shadow: var(--boxShadow);

            .account-menu-logout {
                margin: 0 auto;
            }
		}
	}

	.account-menu-item {
		display: block;
		margin-bottom: var(--accountMenuItemMarginBottom);

		&.active {
			font-weight: 700 !important;
		}

		&::first-letter {
			text-transform: uppercase;
		}
	}

	.account-menu-section-title {
		margin-top: var(--accountMenuSectionTitleMarginTop);
		margin-bottom: var(--accountMenuItemMarginBottom);
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.TabletSizeMin}) and (min-width: ${(props) => props.theme.mediaQuery.TabletSizeS}) {
		--menuPadding: var(--sp5x) var(--sp5x) var(--sp13x) var(--sp5x);
		--menuItemMarginBottom: var(--sp3x);
		--fullNameMarginTop: var(--sp1-5x);
		--userSectionPadding: var(--sp4x);

		.menu {
			width: var(--sp27x);
		}

		.menu-account {
			width: var(--sp28x);
		}
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.TabletSizeSMin}) {
		--menuPadding: var(--sp4x) var(--sp4x) var(--sp12x) var(--sp4x);
		--menuItemMarginBottom: var(--sp3x);
		--fullNameMarginTop: var(--sp1x);
		--userSectionPadding: var(--sp3x);

		.menu {
			width: var(--sp23x);
		}

		.menu-account {
			width: var(--sp24x);
		}
	}
`

export default BottomBarNavigationStyle
