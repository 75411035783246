import styled from 'styled-components'

const RotationRejectionStyle = styled.div`
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	z-index: 99999999;
	background-color: var(--pureWhite);

	.rotation-rejection-title {
		text-align: center;
	}

	.rotation-rejection-description {
		text-align: center;
		margin-top: var(--sp2x);
		max-width: var(--rotationRejectionDescriptionMaxWidth);
	}
`

export default RotationRejectionStyle
