import { useMemo, useEffect, useCallback, useRef } from 'react'
import { useRouter } from 'next/router'

//* HOC's
import { withLanguageContext, withUserContext } from 'context'

//* Components
import Button from 'components/common/Button'
import Text from 'components/common/Text'
import CustomLink from 'components/common/CustomLink'
import CustomUpload from 'components/common/Form/FormUpload'
import FormContainer from 'components/common/Form/FormContainer'

//* Helpers
import { config } from 'helpers'

//* Style
import BottomBarNavigationStyle from './style'

const BottomBarNavigation = ({ user, setIsBurgerMenuActive, isBurgerMenuActive, isAccountMenuActive, setIsAccountMenuActive, translate, updateAvatar, logout }) => {
    //! Refs
    const form = useRef()
    const defaultScroll = useRef(0)

    //! Router
    const router = useRouter()

    useEffect(() => {
        setIsBurgerMenuActive(false)
    }, [router])

    const onFinish = useCallback((values) => {
        return updateAvatar(values)
    }, [])

    //! Menu
    const storeMenuList = useMemo(() => {
        return Object.values(config.routes).map((item, index) => {
            return (
                item.headerMenu && (
                    <li key={index}>
                        <CustomLink
                            className={'p p2 font-jakarta-sans-medium birodo-green menu-item'}
                            url={item.path}
                        >
                            {item.name}
                        </CustomLink>
                    </li>
                )
            )
        })
    }, [config])

    const handleAccountMenuClick = () => {
        setIsBurgerMenuActive(false)
        setIsAccountMenuActive(!isAccountMenuActive)
    }

    const handleMenuClick = () => {
        setIsBurgerMenuActive(!isBurgerMenuActive)
        setIsAccountMenuActive(false)
    }

    useEffect(() => {
        setIsAccountMenuActive(false)
        setIsBurgerMenuActive(false)
    }, [router])

    useEffect(() => {
        window.addEventListener('scroll', () => {
            setIsAccountMenuActive(false), setIsBurgerMenuActive(false)
        })

        return () => {
            window.removeEventListener('scroll', () => {
                setIsAccountMenuActive(false), setIsBurgerMenuActive(false)
            })
        }
    }, [])

    return (
        <BottomBarNavigationStyle>
            <div className={`button-wrap ${isBurgerMenuActive ? 'active' : ''}`}>
                <Button
                    className={'bottom-bar-first-button'}
                    btnType={'icon'}
                    isBottomBar={true}
                    iconName={isBurgerMenuActive ? 'cross' : 'menu'}
                    iconColor={'birodoGreen'}
                    backgroundColor={'maltedMint'}
                    onClick={handleMenuClick}
                />
                {user ? (
                    <Button
                        btnType={'icon'}
                        isBottomBar={true}
                        iconName={isAccountMenuActive ? 'cross' : 'account'}
                        backgroundColor={'lemonPie'}
                        iconColor={'birodoGreen'}
                        onClick={handleAccountMenuClick}
                    />
                ) : (
                    <Button
                        btnType={'icon'}
                        isBottomBar={true}
                        iconName={isAccountMenuActive ? 'cross' : 'account'}
                        backgroundColor={'lemonPie'}
                        iconColor={'birodoGreen'}
                        url={config.routes.signIn.path}
                    />
                )}
            </div>
            {isBurgerMenuActive && (
                <div className='menu'>
                    <ul>{storeMenuList}</ul>
                </div>
            )}

            {isAccountMenuActive && (
                <div className='menu-account'>
                    <div className='user'>
                        <FormContainer
                            ref={form}
                            onFinish={onFinish}
                        >
                            <CustomUpload
                                avatar={user?.avatar}
                                formRef={form}
                                autoFinish={true}
                                status={{ text: user?.status }}
                            />
                        </FormContainer>
                        <div className='full-name'>
                            {user?.first_name && (
                                <Text
                                    className={'p p2 font-jakarta-sans-medium'}
                                    text={`${user?.first_name}`}
                                />
                            )}
                            &nbsp;
                            {user?.last_name && (
                                <Text
                                    className={'p p2 font-jakarta-sans-medium'}
                                    text={`${user?.last_name}`}
                                />
                            )}
                        </div>
                    </div>
                    <div className='routes'>
                        <CustomLink
                            className={'p p2 font-jakarta-sans-medium birodo-green account-menu-item'}
                            url={config.routes.account.path}
                            exact={true}
                        >
                            {translate('home')}
                        </CustomLink>
                        <Text
                            className={'p p3 font-jakarta-sans-medium modern-mint account-menu-section-title'}
                            text={'events'}
                        />
                        <CustomLink
                            className={'p p2 font-jakarta-sans-medium birodo-green account-menu-item'}
                            url={config.routes.savedEvents.path}
                            exact={true}
                        >
                            {translate('saved')}
                        </CustomLink>
                        <Text
                            className={'p p3 font-jakarta-sans-medium modern-mint account-menu-section-title'}
                            text={'clubs'}
                        />
                        <CustomLink
                            className={'p p2 font-jakarta-sans-medium birodo-green account-menu-item'}
                            url={config.routes.recommendedClubs.path}
                            exact={true}
                        >
                            {translate('recommended')}
                        </CustomLink>
                        <CustomLink
                            className={'p p2 font-jakarta-sans-medium birodo-green account-menu-item'}
                            url={config.routes.myClubs.path}
                            exact={true}
                        >
                            {translate('myClubs')}
                        </CustomLink>
                        <CustomLink
                            className={'p p2 font-jakarta-sans-medium birodo-green account-menu-item'}
                            url={config.routes.pendingClubs.path}
                            exact={true}
                        >
                            {translate('pending')}
                        </CustomLink>
                        <CustomLink
                            className={'p p2 font-jakarta-sans-medium birodo-green account-menu-item'}
                            url={config.routes.savedClubs.path}
                            exact={true}
                        >
                            {translate('saved')}
                        </CustomLink>
                        <Text
                            className={'p p3 font-jakarta-sans-medium modern-mint account-menu-section-title'}
                            text={'account'}
                        />
                        <CustomLink
                            className={'p p2 font-jakarta-sans-medium birodo-green account-menu-item'}
                            url={config.routes.accountSettings.path}
                            exact={true}
                        >
                            {translate('accountSettings')}
                        </CustomLink>
                        <CustomLink
                            className={'p p2 font-jakarta-sans-medium birodo-green account-menu-item'}
                            url={config.routes.passwordSettings.path}
                            exact={true}
                        >
                            {translate('passwordSettings')}
                        </CustomLink>
                        <Button
                            className={'p p2 font-jakarta-sans-medium account-menu-logout'}
                            btnType={'link'}
                            text={'logout'}
                            onClick={logout}
                        />
                    </div>
                </div>
            )}
        </BottomBarNavigationStyle>
    )
}

export default withLanguageContext(withUserContext(BottomBarNavigation, ['user', 'updateAvatar', 'logout']), ['translate'])
