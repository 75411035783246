import { createGlobalStyle } from 'styled-components'

const Typography = createGlobalStyle`
    body {
        width: 100%;
        min-height: 100vh;
        margin: 0;
        padding: 0;
        overflow-x: hidden;
        position: relative;
        overflow: ${(props) => (props.popupIsOpen ? 'hidden' : '')};
        background-color: ${(props) => props.bodyColor};
        ${(props) => (props.isSubscribePage ? 'background-color: var(--pureWhite);' : '')}
        ${(props) => (props.is404 ? 'background-color: var(--pureWhite);' : '')}
        color: var(--birodoGreen);
        
        main {
            position: relative;
            display: flex;
            flex-direction: column;
            min-height: 100vh;
            z-index: 1;
            transition: margin-top 1s;
        }
        
        #__next {
            min-height: 100vh;
        }

        &.hidden {
            overflow: hidden;
            max-height: 100vh;
        }
    }

    ul,
    fieldset {
        margin: 0;
        padding: 0;
        list-style: none;
        border: none;
    }

    p {
        margin-block-start: 0;
        margin-block-end: 0;
    }

    button{
        padding: 0;
        border-width: 0;
        border-color: inherit;
        background-color: inherit;
    }

    * {
        box-sizing: border-box;
        -webkit-appearance: none;
        outline: none;
        -webkit-tap-highlight-color: rgba(0,0,0,0) !important; //! Touch highlight color 
        -webkit-font-smoothing: antialiased;
        -webkit-text-size-adjust: 100%;
    }

    input[type="search"]::-webkit-search-decoration,
    input[type="search"]::-webkit-search-cancel-button,
    input[type="search"]::-webkit-search-results-button,
    input[type="search"]::-webkit-search-results-decoration {
        -webkit-appearance: none;
    }

    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    input[type=number] {
        -moz-appearance: textfield;
    }

    :root {
        --primaryColor1: var(--birodoGreen);
        --backgroundColor: var(--white);
    }

    input:-internal-autofill-selected {
        background-color: transparent !important;
        box-shadow: inset 1000px 1000px var(--primaryColor1);
    }

    input:focus::placeholder {
        color: transparent;
    }

    input {
        outline: none;
    }

    input:-webkit-autofill:not(:focus) {
        -webkit-text-fill-color: var(--primaryColor1) !important;
        box-shadow: inset 0 0 0 1000px var(--backgroundColor) !important;
        border-color: var(--backgroundColor) !important;
    }

    //! Change the transparent to any color 
    input:-webkit-autofill,
    input:-webkit-autofill:hover,
    input:-webkit-autofill:focus,
    textarea:-webkit-autofill,
    textarea:-webkit-autofill:hover,
    textarea:-webkit-autofill:focus,
    select:-webkit-autofill,
    select:-webkit-autofill:hover,
    select:-webkit-autofill:focus {
        border-color: var(--backgroundColor);
        -webkit-text-fill-color: var(--primaryColor1);
        color: var(--primaryColor1);
        background-color: var(--backgroundColor);
        box-shadow: 0 0 0 1000px var(--backgroundColor) inset;
        transition: background-color 5000s ease-in-out 0s;
    }

    @keyframes autofill {
        to {
            background: var(--backgroundColor);
        }
    }

    a {
        text-decoration: none;
        color: unset;
        -webkit-tap-highlight-color: transparent;
    }

    //! Font sizes
    .h1,
    h1 {
        font-size: var(--h1);
        margin: 0;

        &[class*='font-jakarta-sans'] {
            line-height: var(--lineHeightH1FontJakarta);
        }

        &[class*='font-reckless-neue'] {
            line-height: var(--lineHeightH1FontReckless);
        }
    }

    .h2,
    h2 {
        font-size: var(--h2);
        margin: 0;

        &[class*='font-jakarta-sans'] {
            line-height: var(--lineHeightH2FontJakarta);
        }

        &[class*='font-reckless-neue'] {
            line-height: var(--lineHeightH2FontReckless);
        }
    }

    .h3,
    h3 {
        font-size: var(--h3);
        margin: 0;

        &[class*='font-jakarta-sans'] {
            line-height: var(--lineHeightH3FontJakarta);
        }

        &[class*='font-reckless-neue'] {
            line-height: var(--lineHeightH3FontReckless);
        }
    }

    .h4,
    h4 {
        font-size: var(--h4);
        margin: 0;

        &[class*='font-jakarta-sans'] {
            line-height: var(--lineHeightH4FontJakarta);
        }

        &[class*='font-reckless-neue'] {
            line-height: var(--lineHeightH4FontReckless);
        }
    }

    .h5,
    h5 {
        font-size: var(--h5);
        margin: 0;

        &[class*='font-jakarta-sans'] {
            line-height: var(--lineHeightH5FontJakarta);
        }

        &[class*='font-reckless-neue'] {
            line-height: var(--lineHeightH5FontReckless);
        }
    }

    .h6,
    h6 {
        font-size: var(--h6);
        margin: 0;

        &[class*='font-jakarta-sans'] {
            line-height: var(--lineHeightH6FontJakarta);
        }

        &[class*='font-reckless-neue'] {
            line-height: var(--lineHeightH6FontReckless);
        }
    }

    .p,
    p {
        --p: var(--p1);

        margin: 0;
        font-size: var(--p);

        &[class*='font-jakarta-sans'] {
            line-height: var(--lineHeightP1FontJakarta);
        }

        &[class*='font-reckless-neue'] {
            line-height: var(--lineHeightP1FontReckless);
        }

        &.p1 {
            --p: var(--p1);

            &[class*='font-jakarta-sans'] {
                line-height: var(--lineHeightP1FontJakarta);
            }

            &[class*='font-reckless-neue'] {
                line-height: var(--lineHeightP1FontReckless);
            }
        }

        &.p2 {
            --p: var(--p2);

            &[class*='font-jakarta-sans'] {
                line-height: var(--lineHeightP2FontJakarta);
            }

            &[class*='font-reckless-neue'] {
                line-height: var(--lineHeightP2FontReckless);
            }
        }
        
        &.p3 {
            --p: var(--p3);

            &[class*='font-jakarta-sans'] {
                line-height: var(--lineHeightP3FontJakarta);
            }

            &[class*='font-reckless-neue'] {
                line-height: var(--lineHeightP3FontReckless);
            }
        }

        &.p4 {
            --p: var(--p4);

            &[class*='font-jakarta-sans'] {
                line-height: var(--lineHeightP4FontJakarta);
            }

            &[class*='font-reckless-neue'] {
                line-height: var(--lineHeightP4FontReckless);
            }
        }
    }

    //! Global error wrapper
    .global-error-wrapper {
        border: 2px solid var(--errorColor);
        border-radius: var(--sp1-5x);
        background-color: rgba(222, 85, 78, 0.05);
        padding: var(--globalErrorPad);
		margin-top: var(--globalErrorWrapMarTB);
		margin-bottom: var(--globalErrorWrapMarTB);


        .comp-title {
            &:last-child{
                margin-top: var(--errorItemMTop);
            }
        }
    }

    //! 404 styles
    .error-404 {
        display:flex;
        align-items: center;
        justify-content: center;
    }

    //! Row
    .row {
        display: flex;
        flex-wrap: wrap;
        margin: 0 calc(calc(var(--colPadding) / 2) * -1);

        > * {
            width: 100%;
            padding: 0 calc(var(--colPadding) / 2);
        }

        .col {
            flex: 1 1;
        }

        ${columnGenerator()}

        @media only screen and (max-width: ${({ theme }) => theme.mediaQuery.DesktopSizeXLMin}) {
            ${columnGenerator('dl')}
        }

        @media only screen and (max-width: ${({ theme }) => theme.mediaQuery.DesktopSizeLMin}) {
            ${columnGenerator('dm')}
        }

        @media only screen and (max-width: ${({ theme }) => theme.mediaQuery.DesktopSizeMMin}) {
            ${columnGenerator('ds')}
        }

        @media only screen and (max-width: ${({ theme }) => theme.mediaQuery.DesktopSizeSMin}) {
            ${columnGenerator('dxs')}
        }

        @media only screen and (max-width: ${({ theme }) => theme.mediaQuery.DesktopSizeXSMin}) {
            ${columnGenerator('t')}
        }
        
        @media only screen and (max-width: ${({ theme }) => theme.mediaQuery.TabletSizeMin}) {
            ${columnGenerator('ts')}
        }

        @media only screen and (max-width: ${({ theme }) => theme.mediaQuery.TabletSizeSMin}) {
            ${columnGenerator('m')}
        }
    }

    @keyframes loading {
        0%, 100% {
            opacity: 0.7;
        }

        50% {
            opacity: 0.5;
        }
    }
`

export default Typography

function columnGenerator(suffix) {
    let style = ''

    for (let i = 1; i <= 12; i++) {
        style += suffix ? `.col-${suffix}-${i}` : `.col-${i}`
        style += `{ width: ${(100 / 12) * i}% } `
    }

    return style
}
