import styled from 'styled-components'

const GlobalErrorStyle = styled.div`
	--errorWrapPadding: var(--sp5x);
	--errorWrapMarginTop: var(--sp5x);
	--errorIconSizes: var(--sp2-5x);
	--errorIconWrapPadding: var(--sp1-5x);
	--bannerSizes: var(--sp6x);
	--errorDescriptionMarginTop: var(--sp1-5x);
	--errorIconWrapMarginRight: var(--sp3x);
	--errorWrapMarginBottom: var(--sp10x);

	display: flex;
	width: 100%;
	border: 2px solid var(--obstinateOrange);
	border-radius: var(--sp1x);
	background-color: var(--pureWhite);
	padding: var(--errorWrapPadding);
	margin-top: var(--errorWrapMarginTop);

	&.margin-bottom {
		margin-top: unset;
		margin-bottom: var(--errorWrapMarginBottom);
	}

	.error-icon {
		font-size: var(--errorIconSizes);

		&::before {
			color: var(--white);
		}
	}

	.error-icon-wrap {
		background-color: var(--obstinateOrange);
		clip-path: polygon(30% 0%, 70% 0%, 100% 30%, 100% 70%, 70% 100%, 30% 100%, 0% 70%, 0% 30%);
		display: flex;
		justify-content: center;
		align-items: center;
		padding: var(--errorIconWrapPadding);
		margin-right: var(--errorIconWrapMarginRight);
		min-width: var(--bannerSizes);
		min-height: var(--bannerSizes);
		max-width: var(--bannerSizes);
		max-height: var(--bannerSizes);
	}

	.error-description {
		margin-top: var(--errorDescriptionMarginTop);
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeXLMin}) and (min-width: ${(props) => props.theme.mediaQuery.DesktopSizeL}) {
		--errorIconWrapMarginRight: var(--sp2x);
		--errorWrapPadding: var(--sp4x);
		--errorWrapMarginTop: var(--sp4x);
		--errorIconSizes: var(--sp2x);
		--errorIconWrapPadding: var(--sp1-5x);
		--bannerSizes: var(--sp5x);
		--errorDescriptionMarginTop: var(--sp1x);
		--errorWrapMarginBottom: var(--sp8x);
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeLMin}) and (min-width: ${(props) => props.theme.mediaQuery.DesktopSizeM}) {
		--errorWrapPadding: var(--sp3x);
		--errorWrapMarginTop: var(--sp4x);
		--errorIconSizes: var(--sp2x);
		--errorIconWrapPadding: var(--sp1-5x);
		--bannerSizes: var(--sp5x);
		--errorDescriptionMarginTop: var(--sp1x);
		--errorIconWrapMarginRight: var(--sp2x);
		--errorWrapMarginBottom: var(--sp7x);
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeMMin}) and (min-width: ${(props) => props.theme.mediaQuery.DesktopSizeS}) {
		--errorWrapPadding: var(--sp3x);
		--errorWrapMarginTop: var(--sp4x);
		--errorIconSizes: var(--sp2x);
		--errorIconWrapPadding: var(--sp1x);
		--bannerSizes: var(--sp4x);
		--errorDescriptionMarginTop: var(--sp1x);
		--errorIconWrapMarginRight: var(--sp2x);
		--errorWrapMarginBottom: var(--sp7x);
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeSMin}) and (min-width: ${(props) => props.theme.mediaQuery.DesktopSizeXS}) {
		--errorWrapPadding: var(--sp3x);
		--errorWrapMarginTop: var(--sp3x);
		--errorIconSizes: var(--sp1-5x);
		--errorIconWrapPadding: var(--sp1x);
		--bannerSizes: var(--sp4x);
		--errorDescriptionMarginTop: var(--sp1x);
		--errorIconWrapMarginRight: var(--sp1-5x);
		--errorWrapMarginBottom: var(--sp7x);
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeXSMin}) and (min-width: ${(props) => props.theme.mediaQuery.TabletSize}) {
		--errorWrapPadding: var(--sp3x);
		--errorWrapMarginTop: var(--sp3x);
		--errorIconSizes: var(--sp1-5x);
		--errorIconWrapPadding: var(--sp1x);
		--bannerSizes: var(--sp4x);
		--errorDescriptionMarginTop: var(--sp1x);
		--errorIconWrapMarginRight: var(--sp1-5x);
		--errorWrapMarginBottom: var(--sp6x);
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.TabletSizeMin}) and (min-width: ${(props) => props.theme.mediaQuery.TabletSizeS}) {
		--errorWrapPadding: var(--sp3x);
		--errorWrapMarginTop: var(--sp3x);
		--errorIconSizes: var(--sp1-5x);
		--errorIconWrapPadding: var(--sp1x);
		--bannerSizes: var(--sp4x);
		--errorDescriptionMarginTop: var(--sp1x);
		--errorIconWrapMarginRight: var(--sp1-5x);
		--errorWrapMarginBottom: var(--sp6x);
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.TabletSizeSMin}) {
		--errorWrapPadding: var(--sp3x);
		--errorWrapMarginTop: var(--sp3x);
		--errorIconSizes: var(--sp1-5x);
		--errorIconWrapPadding: var(--sp1x);
		--bannerSizes: var(--sp4x);
		--errorDescriptionMarginTop: var(--sp1x);
		--errorIconWrapMarginRight: var(--sp1-5x);
		--errorWrapMarginBottom: var(--sp6x);
	}
`

export default GlobalErrorStyle
