import React, { memo as Memo, useCallback, useMemo } from 'react'

//* Helper
import formValidation from '../../../../helpers/_formValidation'

//* Components
import { Form } from 'antd'
import Translate from 'components/common/Translate'

const FormItem = Memo(({ label, name, type, validationType, className, required = true, min, max, message, autocomplete = 'new-password', extra, extraChild, rules = [], options = {}, ...props }) => {
    const newRules = useMemo(() => {
        let r = [...rules]

        if (type == 'email') {
            r.push({ transform: (value) => value.trim() })
        }

        const typeObj = type ? { type } : ''
        r[0] = { ...r?.[0], message: '', required, ...typeObj }

        const params = r[0]

        if (required || validationType) {
            r[1] = ({ getFieldValue }) => ({
                validator(_, value) {
                    const validType = validationType || params.type || 'string'
                    let validate

                    let settings
                    settings = min || typeof min === 'number' ? { ...settings, min: min } : settings
                    settings = max ? { ...settings, max: max } : settings

                    if (name === 'password_confirmation') {
                        validate = formValidation(Translate, { pass: getFieldValue('password'), c_pass: value }, 'c_password', value ? message : name + 'IsRequired')
                    } else {
                        validate = formValidation(Translate, value, validType, message || name + 'IsRequired')
                    }

                    if (validate === true) {
                        return Promise.resolve()
                    }

                    return Promise.reject(validate)
                },
            })
        }

        return r
    }, [name, type, required, validationType, rules, min, max])

    return (
        <Form.Item
            onChange={props.onChange}
            name={name}
            label={label && label}
            extra={extra && (extraChild || extra)}
            className={className || ''}
            rules={newRules}
            hasFeedback
            {...options}
        >
            {React.cloneElement(props.children, { autoComplete: autocomplete || 'newpassword' })}
        </Form.Item>
    )
})

export default FormItem
