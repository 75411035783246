import { forwardRef, useId } from 'react'
import Link from 'next/link'
import { useRouter } from 'next/router'

//* HOC's
import withLanguageContext from 'context/consumerHOC/LanguageConsumer'

const CustomLink = forwardRef(({ selectedLang, options, target, exact = false, ...props }, ref) => {
    const id = useId()

    const { pathname } = useRouter()
    const isActive = exact ? pathname === props.url : pathname.startsWith(props.url)

    return (
        <Link
            scroll={props.scroll}
            shallow={props.shallow}
            href={props.url}
            ref={ref}
            id={id}
            rel={props.rel}
            className={`link-item ${isActive ? 'active' : ''} ${props.className || ''}`}
            aria-label={props.ariaLabel}
            onMouseOver={props.onMouseOver}
            onMouseOut={props.onMouseOut}
            onMouseEnter={props.onMouseEnter}
            onMouseLeave={props.onMouseLeave}
            onClick={props.onClick}
            target={target}
            {...options}
        >
            {props.content || props.children}
        </Link>
    )
})

CustomLink.displayName = 'CustomLink'

export default withLanguageContext(CustomLink, ['selectedLang'])
