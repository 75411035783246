import styled from 'styled-components'

const CustomUploadStyle = styled.div`
	--uploadWidthAndHeight: var(--sp15x);
	--uploadIconSizes: var(--sp8x);
	--innerIconSizes: var(--sp4x);
	--statusPadding: var(--sp1-5x) var(--sp2-5x);

	width: fit-content;
	position: relative;

	.ant-form-item {
		margin-bottom: unset;
	}

	.status {
		position: absolute;
		top: 0;
		left: 80%;
		z-index: 10;
		border-radius: var(--sp25x);
		padding: var(--statusPadding);

		&::first-letter {
			text-transform: uppercase;
		}

		&.pending {
			background-color: var(--goldenChurro);
		}

		&.disapproved {
			background-color: var(--obstinateOrange);
			color: var(--white);
		}

		&.approved {
			background-color: var(--naturalOrchestra);
			color: var(--white);
		}
	}

	.upload {
		position: relative;
		display: flex;
		justify-content: center;
		align-items: center;
		width: var(--uploadWidthAndHeight);
		height: var(--uploadWidthAndHeight);
		border-radius: 50%;
		background-color: var(--birodoGreen);
		overflow: hidden;
		cursor: pointer;

		.inner-icon {
			font-size: var(--innerIconSizes);

			&::before {
				color: var(--white);
			}
		}

		@media (hover: hover) {
			&:hover {
				.layer-upload,
				.layer-change {
					opacity: 80%;
				}
			}
		}

		.layer-upload,
		.layer-change {
			position: absolute;
			top: 0;
			left: 0;
			display: flex;
			justify-content: center;
			align-items: center;
			width: 100%;
			height: 100%;
			background-color: var(--birodoGreen);
			opacity: 0;
			transition: var(--trTime);
			z-index: 1;
		}

		.uploaded-image {
			--proportion: unset;

			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			display: flex;
			justify-content: center;
			align-items: center;
			width: 100%;
			height: 100%;
			border-radius: 50%;
		}

		.upload-icon {
			font-size: var(--uploadIconSizes);

			&::before {
				color: var(--lemonPie);
			}
		}
	}

	.upload-input {
		display: none !important;
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeXLMin}) and (min-width: ${(props) => props.theme.mediaQuery.DesktopSizeL}) {
		--uploadWidthAndHeight: var(--sp11x);
		--uploadIconSizes: var(--sp6x);
		--innerIconSizes: var(--sp3x);
		--statusPadding: var(--sp1x) var(--sp2x);
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeLMin}) and (min-width: ${(props) => props.theme.mediaQuery.DesktopSizeM}) {
		--uploadWidthAndHeight: var(--sp9x);
		--uploadIconSizes: var(--sp5x);
		--innerIconSizes: var(--sp2-5x);
		--statusPadding: var(--sp1x) var(--sp2x);
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeMMin}) and (min-width: ${(props) => props.theme.mediaQuery.DesktopSizeS}) {
		--uploadWidthAndHeight: var(--sp9x);
		--uploadIconSizes: var(--sp5x);
		--innerIconSizes: var(--sp2-5x);
		--statusPadding: var(--sp1x) var(--sp2x);
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeSMin}) and (min-width: ${(props) => props.theme.mediaQuery.DesktopSizeXS}) {
		--uploadWidthAndHeight: var(--sp7x);
		--uploadIconSizes: var(--sp4x);
		--innerIconSizes: var(--sp2x);
		--statusPadding: var(--sp1x) var(--sp2x);
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeXSMin}) and (min-width: ${(props) => props.theme.mediaQuery.TabletSize}) {
		--uploadWidthAndHeight: var(--sp7x);
		--uploadIconSizes: var(--sp3x);
		--innerIconSizes: var(--sp2x);
		--statusPadding: var(--sp1x) var(--sp2x);
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.TabletSizeMin}) and (min-width: ${(props) => props.theme.mediaQuery.TabletSizeS}) {
		--uploadWidthAndHeight: var(--sp7x);
		--uploadIconSizes: var(--sp3x);
		--innerIconSizes: var(--sp2x);
		--statusPadding: var(--sp1x) var(--sp2x);
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.TabletSizeSMin}) {
		--uploadWidthAndHeight: var(--sp7x);
		--uploadIconSizes: var(--sp3x);
		--innerIconSizes: var(--sp2x);
		--statusPadding: var(--sp1x) var(--sp1-5x);
	}
`

export default CustomUploadStyle
