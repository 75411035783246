import { memo as Memo, useMemo } from 'react'

//* Components
import Logo from 'components/common/Logo'
import Text from 'components/common/Text'
import Button from 'components/common/Button'
import CustomLink from 'components/common/CustomLink'

//* HOC's
import withLanguageContext from 'context/consumerHOC/LanguageConsumer'
import withDataContext from 'context/consumerHOC/DataConsumer'
import withUserContext from 'context/consumerHOC/UserConsumer'

//* Helper
import config from 'helpers/_config'

//* Style
import FooterStyle from './style'

const AppFooter = Memo(({ global, selectedLang, user }) => {
    //! Global data
    const globalData = useMemo(() => global?.[selectedLang], [global, selectedLang])

    //! Header list
    const storeFooterLeftList = useMemo(() => {
        return Object.values(config.routes).map((item, index) => {
            return (
                item.footerMenuLeft && (
                    <li
                        key={index}
                        className={'link-wrap'}
                    >
                        <CustomLink
                            className={'p p2 font-jakarta-sans-medium white list-item'}
                            url={item.path}
                        >
                            {item.name}
                        </CustomLink>
                        <CustomLink
                            className={'p p2 font-jakarta-sans-medium white fake-item'}
                            url={item.path}
                        >
                            {item.name}
                        </CustomLink>
                    </li>
                )
            )
        })
    }, [config])

    const storeFooterRightList = useMemo(() => {
        return Object.values(config.routes).map((item, index) => {
            return (
                item.footerMenuRight && (
                    <li
                        key={index}
                        className={'link-wrap'}
                    >
                        <CustomLink
                            className={'p p2 font-jakarta-sans-medium white list-item'}
                            url={item.path}
                        >
                            {item.name}
                        </CustomLink>
                        <CustomLink
                            className={'p p2 font-jakarta-sans-medium white fake-item'}
                            url={item.path}
                        >
                            {item.name}
                        </CustomLink>
                    </li>
                )
            )
        })
    }, [config])

    return (
        globalData && (
            <FooterStyle>
                <div className='first-line'>
                    <Logo
                        type={'footer'}
                        className={'footer'}
                    />
                </div>
                <div className='second-line'>
                    <div className='left-right-wrap'>
                        <div className='left'>
                            <Text
                                className={'h3 white font-reckless-neue-medium footer-title'}
                                text={globalData.footer_join_us_title}
                            />
                            <Button
                                url={user ? config.routes.account.path : config.routes.signUp.path}
                                text={'joinUs'}
                                color={'birodoGreen'}
                                backgroundColor={'lemonPie'}
                                className={'footer-btn'}
                            />
                        </div>
                        <div className='right'>
                            <div className='company'>
                                <Text
                                    className={'h6 list-title mandys-pink font-reckless-neue-medium'}
                                    text={'company'}
                                />
                                <ul>{storeFooterLeftList}</ul>
                            </div>
                            {Object.values(globalData.socials).filter((item) => item !== null).length !== 0 && (
                                <div className='social'>
                                    <Text
                                        className={'h6 list-title mandys-pink font-reckless-neue-medium'}
                                        text={'social'}
                                    />
                                    <ul>
                                        {globalData.socials.instagram && (
                                            <li className='link-wrap'>
                                                <a
                                                    className={'p p2 font-jakarta-sans-medium white list-item'}
                                                    href={globalData.socials.instagram}
                                                    target={'_blank'}
                                                    rel='noopener noreferrer'
                                                    aria-label='instagram'
                                                >
                                                    Instagram
                                                </a>
                                                <a
                                                    className={'p p2 font-jakarta-sans-medium white fake-item'}
                                                    href={globalData.socials.instagram}
                                                    target={'_blank'}
                                                    rel='noopener noreferrer'
                                                    aria-label='instagram'
                                                >
                                                    Instagram
                                                </a>
                                            </li>
                                        )}
                                        {globalData.socials.linkedin && (
                                            <li className='link-wrap'>
                                                <a
                                                    className={'p p2 font-jakarta-sans-medium white list-item'}
                                                    href={globalData.socials.linkedin}
                                                    target={'_blank'}
                                                    rel='noopener noreferrer'
                                                    aria-label='linkedin'
                                                >
                                                    Linkedin
                                                </a>
                                                <a
                                                    className={'p p2 font-jakarta-sans-medium white fake-item'}
                                                    href={globalData.socials.linkedin}
                                                    target={'_blank'}
                                                    rel='noopener noreferrer'
                                                    aria-label='linkedin'
                                                >
                                                    Linkedin
                                                </a>
                                            </li>
                                        )}
                                        {globalData.socials.youtube && (
                                            <li className='link-wrap'>
                                                <a
                                                    className={'p p2 font-jakarta-sans-medium white list-item'}
                                                    href={globalData.socials.youtube}
                                                    target={'_blank'}
                                                    rel='noopener noreferrer'
                                                    aria-label='youtube'
                                                >
                                                    Youtube
                                                </a>
                                                <a
                                                    className={'p p2 font-jakarta-sans-medium white fake-item'}
                                                    href={globalData.socials.youtube}
                                                    target={'_blank'}
                                                    rel='noopener noreferrer'
                                                    aria-label='youtube'
                                                >
                                                    Youtube
                                                </a>
                                            </li>
                                        )}
                                         {globalData.socials.twitter && (
                                            <li className='link-wrap'>
                                                <a
                                                    className={'p p2 font-jakarta-sans-medium white list-item'}
                                                    href={globalData.socials.twitter}
                                                    target={'_blank'}
                                                    rel='noopener noreferrer'
                                                    aria-label='twitter (x)'
                                                >
                                                    Twitter (X)
                                                </a>
                                                <a
                                                    className={'p p2 font-jakarta-sans-medium white fake-item'}
                                                    href={globalData.socials.twitter}
                                                    target={'_blank'}
                                                    rel='noopener noreferrer'
                                                    aria-label='twitter (x)'
                                                >
                                                    Twitter (X)
                                                </a>
                                            </li>
                                        )}
                                          {globalData.socials.tik_tok && (
                                            <li className='link-wrap'>
                                                <a
                                                    className={'p p2 font-jakarta-sans-medium white list-item'}
                                                    href={globalData.socials.tik_tok}
                                                    target={'_blank'}
                                                    rel='noopener noreferrer'
                                                    aria-label='TikTok'
                                                >
                                                    TikTok
                                                </a>
                                                <a
                                                    className={'p p2 font-jakarta-sans-medium white fake-item'}
                                                    href={globalData.socials.tik_tok}
                                                    target={'_blank'}
                                                    rel='noopener noreferrer'
                                                    aria-label='TikTok'
                                                >
                                                    TikTok
                                                </a>
                                            </li>
                                        )}
                                        {globalData.socials.pinterest && (
                                            <li className='link-wrap'>
                                                <a
                                                    className={'p p2 font-jakarta-sans-medium white list-item'}
                                                    href={globalData.socials.pinterest}
                                                    target={'_blank'}
                                                    rel='noopener noreferrer'
                                                    aria-label='Pinterest'
                                                >
                                                    Pinterest
                                                </a>
                                                <a
                                                    className={'p p2 font-jakarta-sans-medium white fake-item'}
                                                    href={globalData.socials.pinterest}
                                                    target={'_blank'}
                                                    rel='noopener noreferrer'
                                                    aria-label='Pinterest'
                                                >
                                                    Pinterest
                                                </a>
                                            </li>
                                        )}
                                    </ul>
                                </div>
                            )}
                            <div className={`legal ${Object.values(globalData.socials).filter((item) => item !== null).length !== 0 ? '' : 'no-socials'}`}>
                                <Text
                                    className={'h6 list-title mandys-pink font-reckless-neue-medium'}
                                    text={'legal'}
                                />
                                <ul>{storeFooterRightList}</ul>
                            </div>
                        </div>
                    </div>
                    <div className='copyright-wrap'>
                        <Text
                            className={'p p3 font-jakarta-sans-medium white'}
                            text={`© ${new Date().getFullYear()} Networkx`}
                        />
                        <div className='copyright-link-wrap'>
                            <Text
                                className={'p p3 font-jakarta-sans-medium white'}
                                text={'websiteBy'}
                            />
                            <a
                                target={'_blank'}
                                href='https://conceptstudio.com/en/'
                                className='p p3 font-jakarta-sans-medium white concept-link'
                                rel='noopener noreferrer'
                                aria-label='Concept studio'
                            >
                                Concept Studio
                            </a>
                        </div>
                    </div>
                </div>
            </FooterStyle>
        )
    )
})

export default withUserContext(withLanguageContext(withDataContext(AppFooter, ['global']), ['selectedLang']), ['user'])
