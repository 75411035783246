import styled from 'styled-components'

const CookiePopupStyle = styled.div`
	--cookiePopupBorderRadius: var(--sp4x);
	--cookiePopupPositionBottom: var(--sp8x);
	--cookiePopupPositionLeft: var(--sp20x);
	--cookiePopupMaxWidth: var(--sp114x);
	--cookiePopupPadding: var(--sp10x);
	--cookiePopupDescriptionMargin: var(--sp4x) 0 var(--sp9x) 0;
	--cookiePopupAcceptButtonMarginLeft: var(--sp4x);

	position: fixed;
	z-index: 100000;
	background-color: var(--cryoFreeze);
	border-radius: var(--cookiePopupBorderRadius);
	bottom: var(--cookiePopupPositionBottom);
	left: var(--cookiePopupPositionLeft);
	max-width: var(--cookiePopupMaxWidth);
	padding: var(--cookiePopupPadding);

	.cookie-description {
		margin: var(--cookiePopupDescriptionMargin);
	}

	.buttons-wrap {
		display: flex;
		align-items: center;
		justify-content: flex-end;

		.accept-cookie-button {
			width: fit-content;
			margin-left: var(--cookiePopupAcceptButtonMarginLeft);
		}
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeXLMin}) and (min-width: ${(props) => props.theme.mediaQuery.DesktopSizeL}) {
		--cookiePopupBorderRadius: var(--sp4x);
		--cookiePopupPositionBottom: var(--sp6x);
		--cookiePopupPositionLeft: var(--sp14x);
		--cookiePopupMaxWidth: var(--sp86x);
		--cookiePopupPadding: var(--sp7x);
		--cookiePopupDescriptionMargin: var(--sp3x) 0 var(--sp6x) 0;
		--cookiePopupAcceptButtonMarginLeft: var(--sp3x);
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeLMin}) and (min-width: ${(props) => props.theme.mediaQuery.DesktopSizeM}) {
		--cookiePopupBorderRadius: var(--sp2-5x);
		--cookiePopupPositionBottom: var(--sp5x);
		--cookiePopupPositionLeft: var(--sp13x);
		--cookiePopupMaxWidth: var(--sp67x);
		--cookiePopupPadding: var(--sp6x);
		--cookiePopupDescriptionMargin: var(--sp3x) 0 var(--sp5x) 0;
		--cookiePopupAcceptButtonMarginLeft: var(--sp3x);
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeMMin}) and (min-width: ${(props) => props.theme.mediaQuery.DesktopSizeS}) {
		--cookiePopupBorderRadius: var(--sp2-5x);
		--cookiePopupPositionBottom: var(--sp5x);
		--cookiePopupPositionLeft: var(--sp12x);
		--cookiePopupMaxWidth: var(--sp67x);
		--cookiePopupPadding: var(--sp6x);
		--cookiePopupDescriptionMargin: var(--sp3x) 0 var(--sp5x) 0;
		--cookiePopupAcceptButtonMarginLeft: var(--sp3x);
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeSMin}) and (min-width: ${(props) => props.theme.mediaQuery.DesktopSizeXS}) {
		--cookiePopupBorderRadius: var(--sp2-5x);
		--cookiePopupPositionBottom: var(--sp5x);
		--cookiePopupPositionLeft: var(--sp10x);
		--cookiePopupMaxWidth: var(--sp56x);
		--cookiePopupPadding: var(--sp5x);
		--cookiePopupDescriptionMargin: var(--sp2x) 0 var(--sp4x) 0;
		--cookiePopupAcceptButtonMarginLeft: var(--sp3x);
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeXSMin}) and (min-width: ${(props) => props.theme.mediaQuery.TabletSize}) {
		--cookiePopupBorderRadius: var(--sp2x);
		--cookiePopupPositionBottom: var(--sp4x);
		--cookiePopupPositionLeft: var(--sp9x);
		--cookiePopupMaxWidth: var(--sp56x);
		--cookiePopupPadding: var(--sp5x);
		--cookiePopupDescriptionMargin: var(--sp2x) 0 var(--sp4x) 0;
		--cookiePopupAcceptButtonMarginLeft: var(--sp3x);
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.TabletSizeMin}) and (min-width: ${(props) => props.theme.mediaQuery.TabletSizeS}) {
		--cookiePopupBorderRadius: var(--sp2x);
		--cookiePopupPositionBottom: var(--sp4x);
		--cookiePopupPositionLeft: var(--sp7x);
		--cookiePopupMaxWidth: var(--sp56x);
		--cookiePopupPadding: var(--sp5x);
		--cookiePopupDescriptionMargin: var(--sp2x) 0 var(--sp4x) 0;
		--cookiePopupAcceptButtonMarginLeft: var(--sp3x);
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.TabletSizeSMin}) {
		--cookiePopupBorderRadius: var(--sp1x);
		--cookiePopupPositionBottom: 0;
		--cookiePopupPositionLeft: 0;
		--cookiePopupMaxWidth: 100%;
		--cookiePopupPadding: var(--sp4x);
		--cookiePopupDescriptionMargin: var(--sp2x) 0 var(--sp3x) 0;
		--cookiePopupAcceptButtonMarginLeft: var(--sp3x);
        
        margin: var(--sp3x);
	}
`

export default CookiePopupStyle
