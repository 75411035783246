import { memo as Memo, useRef, useCallback } from 'react'

//* Component's
import { Input } from 'antd'

const FormInput = Memo((props) => {
    //! Refs
    const ref = useRef()

    //! On Wheel
    const onWheel = useCallback((e) => e.preventDefault(), [])

    //! On Focus
    const onFocus = useCallback(() => {
        if (ref.current?.props?.type === 'number') {
            ref.current.input.addEventListener('wheel', onWheel)
        }
    }, [ref, onWheel])

    //! On Blur
    const onBlur = useCallback(() => {
        if (ref.current?.props?.type === 'number') {
            ref.current.input.removeEventListener('wheel', onWheel)
        }
    }, [ref, onWheel])

    return (
        <Input
            prefix={props.prefix}
            suffix={props.suffix}
            addonBefore={props.addonBefore}
            onFocus={onFocus}
            onBlur={onBlur}
            ref={ref}
            {...props}
            step={props.step && '0.1'}
        />
    )
})

export default FormInput
