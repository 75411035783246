import styled from 'styled-components'

const ButtonStyle = styled.div`
	--smallButtonPadding: var(--sp3x) var(--sp5x);
	--smallButtonBorderRadius: var(--sp1x);

	--bigButtonPadding: var(--sp7x);
	--bigButtonBorderRadius: var(--sp4x);

	--iconBoxSizes: var(--sp7x);
	--iconSizes: var(--sp4x);

	--socialBoxSizes: var(--sp18x);
	--socialBorderRadius: var(--sp2-5x);

	--favoriteBoxSizes: var(--sp8x);
	--favoriteIconSizes: var(--sp5x);
	--favoriteBigSizes: var(--sp9x);

	&.disable {
		opacity: 50%;
		pointer-events: none;
	}

	button,
	a {
		background-color: ${(props) => `var(--${props.backgroundColor})`};
		color: ${(props) => `var(--${props.color})`};
		${(props) => (props.borderColor ? `border: var(--sp0-5x) solid var(--${props.borderColor});` : '')};
		font-family: var(--plusJakartaSans);
		font-weight: 600;
		display: flex;
		justify-content: center;
		cursor: pointer;
		width: 100%;

		.content-wrap {
			position: relative;
			overflow: hidden;

			.fake-text {
				position: absolute;
				top: 0;
				left: -120%;
				white-space: nowrap;
				transition: 0.8s;
			}

			.real-text {
				transition: 0.8s;
				position: relative;
				top: 0;
				left: 0;
			}
		}

		@media (hover: hover) {
			&:hover {
				.content-wrap {
					.fake-text {
						left: 0;
					}

					.real-text {
						left: 120%;
					}
				}
			}
		}
	}

	&.small {
		button,
		a {
			border-radius: var(--smallButtonBorderRadius);
			padding: var(--smallButtonPadding);
			font-size: var(--p2);
			color: ${(props) => `var(--${props.color})`};
		}
	}

	&.big {
		button,
		a {
			border-radius: var(--bigButtonBorderRadius);
			padding: var(--bigButtonPadding);
			font-size: var(--h5);
			color: ${(props) => `var(--${props.color})`};
		}
	}

	&.border {
		button,
		a {
			border: var(--sp0-5x) solid var(--birodoGreen);
			background-color: unset;
			color: var(--birodoGreen);
		}
	}

	&.link {
		color: ${(props) => `var(--${props.color})`};
		cursor: pointer;
		width: fit-content;

		button,
		a {
			.content-wrap {
				.real-text {
					font-size: var(--p2);
					text-decoration: underline;
				}

				.fake-text {
					font-size: var(--p2);
					text-decoration: underline;
				}
			}
		}
	}

	&.vertical {
		button,
		a {
			height: 100%;
			display: flex;
			align-items: center;
			justify-content: center;

			.content-wrap {
				display: flex;
				justify-content: center;
				align-items: center;
				transform: rotate(180deg);

				.fake-text {
					position: absolute;
					top: -120%;
					left: 0;
					white-space: nowrap;
					transition: 0.8s;
					-webkit-writing-mode: vertical-rl;
					writing-mode: vertical-rl;
					text-orientation: mixed;
					white-space: nowrap;
				}

				.real-text {
					transition: 0.8s;
					position: unset;
					transform: translateY(0%);
					-webkit-writing-mode: vertical-rl;
					writing-mode: vertical-rl;
					text-orientation: mixed;
					white-space: nowrap;
				}
			}
		}

		@media (hover: hover) {
			&:hover {
				.content-wrap {
					.fake-text {
						top: 0;
					}

					.real-text {
						transform: translateY(120%);
					}
				}
			}
		}
	}

	&.icon {
		button,
		a {
			width: var(--iconBoxSizes);
			height: var(--iconBoxSizes);
			border-radius: var(--sp1x);
			background-color: var(--birodoGreen);
			background-color: ${(props) => `var(--${props.backgroundColor})`};
			display: flex;
			justify-content: center;
			align-items: center;

			.icon {
				font-size: var(--iconSizes);

				&::before {
					color: ${(props) => `var(--${props.iconColor})`};
				}
			}
		}
	}

	&.social {
		display: inline-flex;

		button,
		a {
			width: var(--socialBoxSizes);
			height: var(--socialBoxSizes);
			display: flex;
			justify-content: center;
			align-items: center;
			border-radius: var(--socialBorderRadius);
			background-color: var(--mandysPink);
			transition: var(--trTime);

			.text-content {
				color: var(--birodoGreen);
			}

			@media (hover: hover) {
				&:hover {
					background-color: var(--fruitRed);
				}
			}
		}
	}

	&.favorite {
		button,
		a {
			width: var(--favoriteBoxSizes);
			height: var(--favoriteBoxSizes);
			display: flex;
			justify-content: center;
			align-items: center;
			border-radius: var(--sp1x);
			background-color: var(--mandysPink);
			transition: var(--trTime);
			padding: unset;

			.icon {
				font-size: var(--favoriteIconSizes);
			}
		}

		&.big {
			button,
			a {
				padding: unset;
				width: var(--favoriteBigSizes);
				height: var(--favoriteBigSizes);
			}
		}

		&.disable {
			opacity: 1;

			button,
			a {
				.icon {
					opacity: 60%;
				}
			}
		}
	}

	&.small-border {
		button,
		a {
			border: 2px solid ${(props) => `var(--${props.borderColor})`};
		}
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeXLMin}) and (min-width: ${(props) => props.theme.mediaQuery.DesktopSizeL}) {
		--smallButtonPadding: var(--sp2x) var(--sp4x);
		--smallButtonBorderRadius: var(--sp1x);

		--bigButtonPadding: var(--sp5x);
		--bigButtonBorderRadius: var(--sp4x);

		--iconBoxSizes: var(--sp6x);
		--iconSizes: var(--sp3x);

		--socialBoxSizes: var(--sp13x);
		--socialBorderRadius: var(--sp2-5x);

		--favoriteBoxSizes: var(--sp6x);
		--favoriteIconSizes: var(--sp4x);
		--favoriteBigSizes: var(--sp7x);
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeLMin}) and (min-width: ${(props) => props.theme.mediaQuery.DesktopSizeM}) {
		--smallButtonPadding: var(--sp2x) var(--sp4x);
		--smallButtonBorderRadius: var(--sp1x);

		--bigButtonPadding: var(--sp4x);
		--bigButtonBorderRadius: var(--sp2-5x);

		--iconBoxSizes: var(--sp5x);
		--iconSizes: var(--sp2-5x);

		--socialBoxSizes: var(--sp11x);
		--socialBorderRadius: var(--sp2x);

		--favoriteBoxSizes: var(--sp5x);
		--favoriteIconSizes: var(--sp3x);
		--favoriteBigSizes: var(--sp7x);
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeMMin}) and (min-width: ${(props) => props.theme.mediaQuery.DesktopSizeS}) {
		--smallButtonPadding: var(--sp2x) var(--sp4x);
		--smallButtonBorderRadius: var(--sp1x);

		--bigButtonPadding: var(--sp4x);
		--bigButtonBorderRadius: var(--sp2-5x);

		--iconBoxSizes: var(--sp5x);
		--iconSizes: var(--sp2-5x);

		--socialBoxSizes: var(--sp11x);
		--socialBorderRadius: var(--sp2x);

		--favoriteBoxSizes: var(--sp5x);
		--favoriteIconSizes: var(--sp3x);
		--favoriteBigSizes: var(--sp6x);
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeSMin}) and (min-width: ${(props) => props.theme.mediaQuery.DesktopSizeXS}) {
		--smallButtonPadding: var(--sp1-5x) var(--sp3x);
		--smallButtonBorderRadius: var(--sp0-5x);

		--bigButtonPadding: var(--sp4x);
		--bigButtonBorderRadius: var(--sp2-5x);

		--iconBoxSizes: var(--sp4x);
		--iconSizes: var(--sp2x);

		--socialBoxSizes: var(--sp11x);
		--socialBorderRadius: var(--sp2x);

		--favoriteBoxSizes: var(--sp5x);
		--favoriteIconSizes: var(--sp3x);
		--favoriteBigSizes: var(--sp5x);
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeXSMin}) and (min-width: ${(props) => props.theme.mediaQuery.TabletSize}) {
		--smallButtonPadding: var(--sp1-5x) var(--sp3x);
		--smallButtonBorderRadius: var(--sp0-5x);

		--bigButtonPadding: var(--sp3x);
		--bigButtonBorderRadius: var(--sp2x);

		--iconBoxSizes: var(--sp4x);
		--iconSizes: var(--sp2x);

		--socialBoxSizes: var(--sp9x);
		--socialBorderRadius: var(--sp2x);

		--favoriteBoxSizes: var(--sp4x);
		--favoriteIconSizes: var(--sp2-5x);
		--favoriteBigSizes: var(--sp5x);

		button,
		a {
			${(props) => (props.borderColor ? `border: 3px solid var(--${props.borderColor});` : '')};
		}

		&.border {
			button,
			a {
				border: 3px solid var(--birodoGreen);
			}
		}

		&.bottom-bar {
			button,
			a {
				width: var(--sp7x);
				height: var(--sp7x);

				.icon {
					font-size: var(--sp3x);
				}
			}
		}
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.TabletSizeMin}) and (min-width: ${(props) => props.theme.mediaQuery.TabletSizeS}) {
		--smallButtonPadding: var(--sp1-5x) var(--sp3x);
		--smallButtonBorderRadius: var(--sp0-5x);

		--bigButtonPadding: var(--sp3x);
		--bigButtonBorderRadius: var(--sp2x);

		--iconBoxSizes: var(--sp4x);
		--iconSizes: var(--sp2x);

		--socialBoxSizes: var(--sp9x);
		--socialBorderRadius: var(--sp1-5x);

		--favoriteBoxSizes: var(--sp4x);
		--favoriteIconSizes: var(--sp2-5x);
		--favoriteBigSizes: var(--sp5x);

		button,
		a {
			${(props) => (props.borderColor ? `border: 3px solid var(--${props.borderColor});` : '')};
		}

		&.border {
			button,
			a {
				border: 3px solid var(--birodoGreen);
			}
		}

		&.vertical {
			width: 100%;

			button,
			a {
				@media (hover: hover) {
					&:hover {
						.content-wrap {
							.fake-text {
								left: 0;
							}

							.real-text {
								position: relative;
								transform: unset;
								left: 120%;
							}
						}
					}
				}

				.content-wrap {
					transform: unset;

					.fake-text {
						top: 0;
						left: -120%;
						-webkit-writing-mode: unset;
						writing-mode: unset;
						text-orientation: unset;
						white-space: unset;
					}

					.real-text {
						top: 0;
						left: 0;
						-webkit-writing-mode: unset;
						writing-mode: unset;
						text-orientation: unset;
						white-space: unset;
					}
				}
			}
		}

		&.bottom-bar {
			button,
			a {
				width: var(--sp7x);
				height: var(--sp7x);

				.icon {
					font-size: var(--sp3x);
				}
			}
		}
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.TabletSizeSMin}) {
		--smallButtonPadding: var(--sp1-5x) var(--sp3x);
		--smallButtonBorderRadius: var(--sp0-5x);

		--bigButtonPadding: var(--sp2x);
		--bigButtonBorderRadius: var(--sp1x);

		--iconBoxSizes: var(--sp4x);
		--iconSizes: var(--sp2x);

		--socialBoxSizes: var(--sp7x);
		--socialBorderRadius: var(--sp1-5x);

		--favoriteBoxSizes: var(--sp4x);
		--favoriteIconSizes: var(--sp2-5x);
		--favoriteBigSizes: var(--sp4x);

		button,
		a {
			${(props) => (props.borderColor ? `border: 3px solid var(--${props.borderColor});` : '')};
		}

		&.border {
			button,
			a {
				border: 3px solid var(--birodoGreen);
			}
		}

		&.vertical {
			width: 100%;

			button,
			a {
				@media (hover: hover) {
					&:hover {
						.content-wrap {
							.fake-text {
								left: 0;
							}

							.real-text {
								position: relative;
								transform: unset;
								left: 120%;
							}
						}
					}
				}

				.content-wrap {
					transform: unset;

					.fake-text {
						top: 0;
						left: -120%;
						-webkit-writing-mode: unset;
						writing-mode: unset;
						text-orientation: unset;
						white-space: unset;
					}

					.real-text {
						top: 0;
						left: 0;
						-webkit-writing-mode: unset;
						writing-mode: unset;
						text-orientation: unset;
						white-space: unset;
					}
				}
			}
		}

		&.bottom-bar {
			button,
			a {
				width: var(--sp6x);
				height: var(--sp6x);

				.icon {
					font-size: var(--sp2-5x);
				}
			}
		}
	}
`

export default ButtonStyle
