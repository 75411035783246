import styled from 'styled-components'

const HeaderStyle = styled.header`
	--headerPadding: var(--sp8x) var(--sp12x);
	--headerSmallPadding: var(--sp4x) var(--sp12x);
	--listItemMargin: var(--sp6x);
	--linkButtonMarginRight: var(--sp5x);
	--notificationIconSizes: var(--sp5x);
	--notificationMenuPadding: var(--sp5x);
	--notificationMenuTop: calc(100% + var(--sp4x));
	--notificationMenuWidth: var(--sp66x);
	--notificationCircleSizes: var(--sp2x);
	--notificationTitleMarginBottom: var(--sp4x);
	--notificationAgoMarginTop: var(--sp1-5x);

	--userIconCircleSizes: var(--sp6x);
	--userIconCircleMarinRight: var(--sp3x);
	--userIconSizes: var(--sp3x);

	--miniCircleSizes: var(--sp2-5x);
	--miniCircleIconSizes: var(--sp1-5x);
	--notifyMarginTop: var(--sp4x);
	--notificationMaxHeight: var(--sp111x);

	position: fixed;
	width: 100%;
	top: -100%;
	transition: top 0.5s;
	z-index: 7;

	&.visible {
		top: 0;
	}

	&:not(.in-top) {
		.header {
			padding: var(--headerSmallPadding);
		}
	}

	&.in-top {
		.header {
			padding: var(--headerPadding);
		}
	}

	&.thank-you {
		.header {
			background-color: var(--sweetAriel);
		}
	}

    &.blog-individual {

        .header {
			background-color: var(--white);
		}
    }

	.header {
		display: flex;
		align-items: center;
		justify-content: space-between;

		&.header-custom-color {
			background-color: var(--sweetAriel);
			.header-list {
				.link-wrap {
					.link-item {
						color: var(--birodoGreen);
					}
				}
			}

			.buttons-wrap {
				.link {
					.link-item {
						color: var(--birodoGreen);
					}
				}
			}
		}

		.header-list {
			display: flex;

			.link-wrap {
				display: flex;
				flex-direction: column;
				position: relative;
				overflow: hidden;
				margin: 0 var(--listItemMargin);
				cursor: pointer;

				@media (hover: hover) {
					&:hover {
						.fake-item {
							top: 0;
						}

						.list-item {
							top: -120%;
						}
					}
				}

				.list-item {
					position: relative;
					top: 0;
					left: 0;
					transition: 0.6s;

					&::first-letter {
						text-transform: uppercase;
					}
				}

				.fake-item {
					position: absolute;
					top: 120%;
					left: 0;
					transition: 0.6s;

					&::first-letter {
						text-transform: uppercase;
					}
				}
			}
		}

		.buttons-wrap {
			display: flex;
			align-items: center;

			.link {
				margin-right: var(--linkButtonMarginRight);

				&.header-account {
					margin-right: unset;
				}
			}

			.notification-wrap {
				position: relative;
				display: flex;
				align-items: center;

				.notification-menu {
					position: absolute;
					top: var(--notificationMenuTop);
					right: 0;
					background-color: var(--cryoFreeze);
					padding: var(--notificationMenuPadding);
					border-radius: var(--sp1x);
					width: var(--notificationMenuWidth);
					max-height: var(--notificationMaxHeight);
					overflow-x: hidden;
					overflow-y: scroll;

					&::-webkit-scrollbar {
						display: none;
						-ms-overflow-style: none;
						scrollbar-width: none;
					}

					.notifications-wrap {
						.notify {
							display: flex;
							margin-top: var(--notifyMarginTop);

							&.approved {
								.user-icon {
									.notification-user {
										&::before {
											color: var(--naturalOrchestra);
										}
									}

									.mini-circle {
										background-color: var(--naturalOrchestra);
									}
								}
							}

							&.disapproved {
								.user-icon {
									.notification-user {
										&::before {
											color: var(--obstinateOrange);
										}
									}

									.mini-circle {
										background-color: var(--obstinateOrange);
									}
								}
							}

							.user-icon {
								position: relative;
								display: flex;
								align-items: center;
								justify-content: center;
								width: var(--userIconCircleSizes);
								height: var(--userIconCircleSizes);
								border-radius: 50%;
								margin-right: var(--userIconCircleMarinRight);
								background-color: var(--pureWhite);

								.mini-circle {
									display: flex;
									justify-content: center;
									align-items: center;
									position: absolute;
									top: -10%;
									right: -10%;
									background-color: var(--white);
									width: var(--miniCircleSizes);
									height: var(--miniCircleSizes);
									border-radius: 50%;

									.mini-circle-icon {
										font-size: var(--miniCircleIconSizes);

										&::before {
											color: var(--white);
										}
									}
								}

								.notification-user {
									font-size: var(--userIconSizes);
								}
							}

							.notify-description {
								width: calc(100% - var(--userIconCircleSizes) - var(--userIconCircleMarinRight));

								.message-part {
									display: inline;
								}

								.ago {
									margin-top: var(--notificationAgoMarginTop);
								}
							}
						}
					}

					.notification-title {
						margin-bottom: var(--notificationTitleMarginBottom);
					}
				}

				.notification {
					margin-right: var(--linkButtonMarginRight);
					font-size: var(--notificationIconSizes);
					cursor: pointer;
					position: relative;

					&.has-notification {
						&::after {
							content: '';
							position: absolute;
							top: 10%;
							right: 10%;
							width: var(--notificationCircleSizes);
							height: var(--notificationCircleSizes);
							background-color: var(--mandysPink);
							border-radius: 50%;
						}
					}
				}
			}
		}
	}

	&.transparent-header {
		.header-fake-background {
			opacity: 0;
		}

		.header-list {
			.list-item {
				color: var(--white);
			}

			.fake-item {
				color: var(--white);
			}
		}

		.buttons-wrap {
			.sign-in {
				.link-item {
					color: var(--white);
				}
			}
		}

		.notification {
			&::before {
				color: var(--white);
			}
		}

		.header-account {
			color: var(--white);
		}
	}

	.header-fake-background {
		position: absolute;
		inset: 0;
		background-color: var(--pureWhite);
		z-index: -1;
		transform: translate3d(0, 0, 0);
		transition: transform var(--trTime);
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeXLMin}) and (min-width: ${(props) => props.theme.mediaQuery.DesktopSizeL}) {
		--headerPadding: var(--sp5x) var(--sp8x);
		--headerSmallPadding: var(--sp3x) var(--sp8x);
		--listItemMargin: var(--sp4x);
		--linkButtonMarginRight: var(--sp4x);
		--notificationIconSizes: var(--sp4x);
		--notificationMenuPadding: var(--sp4x);
		--notificationMenuTop: calc(100% + var(--sp3-5x));
		--notificationMenuWidth: var(--sp50x);
		--notificationCircleSizes: var(--sp1-5x);
		--notificationTitleMarginBottom: var(--sp3x);
		--notificationAgoMarginTop: var(--sp1x);
		--userIconCircleSizes: var(--sp4x);
		--userIconCircleMarinRight: var(--sp2x);
		--userIconSizes: var(--sp2x);
		--miniCircleSizes: var(--sp2x);
		--miniCircleIconSizes: var(--sp1x);
		--notifyMarginTop: var(--sp2-5x);
		--notificationMaxHeight: var(--sp86x);
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeLMin}) and (min-width: ${(props) => props.theme.mediaQuery.DesktopSizeM}) {
		--headerPadding: var(--sp5x) var(--sp7x);
		--headerSmallPadding: var(--sp2x) var(--sp7x);
		--listItemMargin: var(--sp3-5x);
		--linkButtonMarginRight: var(--sp4x);
		--notificationIconSizes: var(--sp4x);
		--notificationMenuPadding: var(--sp4x);
		--notificationMenuTop: calc(100% + var(--sp4x));
		--notificationMenuWidth: var(--sp44x);
		--notificationCircleSizes: var(--sp1-5x);
		--notificationTitleMarginBottom: var(--sp3x);
		--notificationAgoMarginTop: var(--sp1x);
		--userIconCircleSizes: var(--sp3x);
		--userIconCircleMarinRight: var(--sp1-5x);
		--userIconSizes: var(--sp1-5x);
		--miniCircleSizes: var(--sp1-5x);
		--miniCircleIconSizes: var(--sp1x);
		--notifyMarginTop: var(--sp3x);
		--notificationMaxHeight: var(--sp94x);
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeMMin}) and (min-width: ${(props) => props.theme.mediaQuery.DesktopSizeS}) {
		--headerPadding: var(--sp5x) var(--sp7x);
		--headerSmallPadding: var(--sp2x) var(--sp7x);
		--listItemMargin: var(--sp3-5x);
		--linkButtonMarginRight: var(--sp4x);
		--notificationIconSizes: var(--sp3x);
		--notificationMenuPadding: var(--sp4x);
		--notificationMenuTop: calc(100% + var(--sp4x));
		--notificationMenuWidth: var(--sp44x);
		--notificationCircleSizes: var(--sp1x);
		--notificationTitleMarginBottom: var(--sp3x);
		--notificationAgoMarginTop: var(--sp1x);
		--userIconCircleSizes: var(--sp3x);
		--userIconCircleMarinRight: var(--sp1-5x);
		--userIconSizes: var(--sp1-5x);
		--miniCircleSizes: var(--sp1-5x);
		--miniCircleIconSizes: var(--sp1x);
		--notifyMarginTop: var(--sp3x);
		--notificationMaxHeight: var(--sp80x);
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeSMin}) and (min-width: ${(props) => props.theme.mediaQuery.DesktopSizeXS}) {
		--headerPadding: var(--sp4x) var(--sp6x);
		--headerSmallPadding: var(--sp2x) var(--sp6x);
		--listItemMargin: var(--sp3x);
		--linkButtonMarginRight: var(--sp3x);
		--notificationIconSizes: var(--sp2-5x);
		--notificationMenuPadding: var(--sp3x);
		--notificationMenuTop: calc(100% + var(--sp3x));
		--notificationMenuWidth: var(--sp41x);
		--notificationCircleSizes: var(--sp1x);
		--notificationTitleMarginBottom: var(--sp2-5x);
		--notificationAgoMarginTop: var(--sp1x);
		--userIconCircleSizes: var(--sp2-5x);
		--userIconCircleMarinRight: var(--sp1-5x);
		--userIconSizes: var(--sp1x);
		--miniCircleSizes: var(--sp1x);
		--miniCircleIconSizes: var(--sp0-5x);
		--notifyMarginTop: var(--sp2-5x);
		--notificationMaxHeight: var(--sp63x);
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeXSMin}) and (min-width: ${(props) => props.theme.mediaQuery.TabletSize}) {
		--headerPadding: var(--sp3x) var(--sp4x);
		--headerSmallPadding: var(--sp1x) var(--sp4x);
		--linkButtonMarginRight: var(--sp3x);
		--notificationIconSizes: var(--sp2-5x);
		--notificationMenuPadding: var(--sp3x);
		--notificationMenuTop: calc(100% + var(--sp3x));
		--notificationMenuWidth: var(--sp41x);
		--notificationCircleSizes: var(--sp1x);
		--notificationTitleMarginBottom: var(--sp2-5x);
		--notificationAgoMarginTop: var(--sp1x);
		--userIconCircleSizes: var(--sp2-5x);
		--userIconCircleMarinRight: var(--sp1-5x);
		--userIconSizes: var(--sp1x);
		--miniCircleSizes: var(--sp1x);
		--miniCircleIconSizes: var(--sp0-5x);
		--notifyMarginTop: var(--sp2-5x);
		--notificationMaxHeight: var(--sp63x);

		.header {
			.header-list {
				display: none;
			}
		}
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.TabletSizeMin}) and (min-width: ${(props) => props.theme.mediaQuery.TabletSizeS}) {
		--headerPadding: var(--sp3x);
		--headerSmallPadding: var(--sp1x) var(--sp3x);
		--linkButtonMarginRight: var(--sp3x);
		--notificationIconSizes: var(--sp2-5x);
		--notificationMenuPadding: var(--sp3x);
		--notificationMenuTop: calc(100% + var(--sp3x));
		--notificationMenuWidth: var(--sp41x);
		--notificationCircleSizes: var(--sp1x);
		--notificationTitleMarginBottom: var(--sp2-5x);
		--notificationAgoMarginTop: var(--sp1x);
		--userIconCircleSizes: var(--sp2-5x);
		--userIconCircleMarinRight: var(--sp1-5x);
		--userIconSizes: var(--sp1x);
		--miniCircleSizes: var(--sp1x);
		--miniCircleIconSizes: var(--sp0-5x);
		--notifyMarginTop: var(--sp2-5x);
		--notificationMaxHeight: var(--sp63x);

		.header {
			.header-list {
				display: none;
			}
		}
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.TabletSizeSMin}) {
		--headerPadding: var(--sp3x);
		--headerSmallPadding: var(--sp1x) var(--sp3x);
		--linkButtonMarginRight: var(--sp2x);
		--notificationIconSizes: var(--sp2-5x);
		--notificationMenuPadding: var(--sp3x);
		--notificationMenuTop: calc(100% + var(--sp2x));
		--notificationMenuWidth: var(--sp41x);
		--notificationCircleSizes: var(--sp1x);
		--notificationTitleMarginBottom: var(--sp2-5x);
		--notificationAgoMarginTop: var(--sp1x);
		--userIconCircleSizes: var(--sp2-5x);
		--userIconCircleMarinRight: var(--sp1-5x);
		--userIconSizes: var(--sp1x);
		--miniCircleSizes: var(--sp1x);
		--miniCircleIconSizes: var(--sp0-5x);
		--notifyMarginTop: var(--sp2-5x);
		--notificationMaxHeight: var(--sp63x);

		.header {
			.header-list {
				display: none;
			}
		}
	}
`

export default HeaderStyle
