import { createGlobalStyle } from 'styled-components'

const HelperClass = createGlobalStyle`
    //! Fonts
    .font-jakarta-sans {
        &-regular {
            font-family: var(--plusJakartaSans);
            font-weight: 400 !important;
        }
        
        &-medium {
            font-family: var(--plusJakartaSans);
            font-weight: 500 !important;
        }

        &-semibold {
            font-family: var(--plusJakartaSans);
            font-weight: 600 !important;
        }

        &-bold {
            font-family: var(--plusJakartaSans);
            font-weight: 700 !important;
        }
    }

    .font-reckless-neue {
        &-medium {
            font-family: var(--recklessNeue);
            font-weight: 500 !important;
        }

        &-semibold {
            font-family: var(--recklessNeue);
            font-weight: 600 !important;
        }
    }

    //! Color's and background's
    .birodo-green {
        color: var(--birodoGreen);

        &-bg {
            background-color: var(--birodoGreen);
            color: var(--white);
        }
    }

    .cryo-freeze {
        color: var(--cryoFreeze);

        &-bg {
            background-color: var(--cryoFreeze);
        }
    }

    .sweet-ariel {
        color: var(--sweetAriel);

        &-bg {
            background-color: var(--sweetAriel);
        }
    }

    .malted-mint {
        color: var(--maltedMint);

        &-bg {
            background-color: var(--maltedMint);
            color: var(--birodoGreen);
        }
    }

    .modern-mint {
        color: var(--modernMint);

        &-bg {
            background-color: var(--modernMint);
        }
    }
    
    .clearly-aqua {
        color: var(--clearlyAqua);

        &-bg {
            background-color: var(--clearlyAqua);
        }
    }

    .juniper-breeze {
        color: var(--juniperBreeze);

        &-bg {
            background-color: var(--juniperBreeze);
        }
    }

    .mandys-pink {
        color: var(--mandysPink);

        &-bg {
            background-color: var(--mandysPink);
        }
    }

    .fruit-red {
        color: var(--fruitRed);

        &-bg {
            background-color: var(--fruitRed);
        }
    }
    
    .obstinate-orange {
        color: var(--obstinateOrange);

        &-bg {
            background-color: var(--obstinateOrange);
        }
    }
    
    .white {
        color: var(--white);

        &-bg {
            background-color: var(--white);
            color: var(--birodoGreen);
        }
    }

    .lemon-pie {
        color: var(--lemonPie);

        &-bg {
            background-color: var(--lemonPie);
        }
    }

    .pure-white {
        color: var(--pureWhite);

        &-bg {
            background-color: var(--pureWhite);
            color: var(--birodoGreen);
        }
    }

    .durian-smell {
        color: var(--durianSmell);

        &-bg {
            background-color: var(--durianSmell);
        }
    }
    
    .hypnotism {
        color: var(--hypnotism);

        &-bg {
            background-color: var(--hypnotism);
        }
    }
    .jasper-park {
        color: var(--jasperPark);

        &-bg {
            background-color: var(--jasperPark);
        }
    }
    .sycamore-grove {
        color: var(--sycamoreGrove);

        &-bg {
            background-color: var(--sycamoreGrove);
        }
    }

    .golden-churro {
        color: var(--goldenChurro);

        &-bg {
            background-color: var(--goldenChurro);
        }
    }

    .natural-orchestra {
        color: var(--naturalOrchestra);

        &-bg {
            background-color: var(--naturalOrchestra);
        }
    }

    .basil-smash {
        color: var(--basilSmash);

        &-bg {
            background-color: var(--basilSmash);
        }
    }

    .flax-bloom {
        color: var(--flaxBloom);

        &-bg {
            background-color: var(--flaxBloom);
        }
    }
    
    .romantic-ballad {
        color: var(--romanticBallad);

        &-bg {
            background-color: var(--romanticBallad);
        }
    }

    .turquoise-tower {
        color: var(--turquoiseTower);

        &-bg {
            background-color: var(--turquoiseTower);
        }
    }
    
    .desire-pink {
        color: var(--desirePink);

        &-bg {
            background-color: var(--desirePink);
        }
    }
    
    .garbanzo-bean {
        color: var(--garbanzoBean);

        &-bg {
            background-color: var(--garbanzoBean);
        }
    }
    
    .cocoa-butter {
        color: var(--cocoaButter);

        &-bg {
            background-color: var(--cocoaButter);
        }
    }

    .pale-olive {
        color: var(--paleOlive);

        &-bg {
            background-color: var(--paleOlive);
        }
    }

    .cultured {
        color: var(--cultured);

        &-bg {
            background-color: var(--cultured);
        }
    }

    .success-light {
        color: var(--successLight);

        &-bg {
            background-color: var(--successLight);
        }
    }

    //! Text transform
    .uppercase {
        text-transform: uppercase;
    }

    .capitalize {
        text-transform: capitalize;
    }
    
    .underline {
        text-decoration: underline;
    }

    .cursorPointer {
        cursor: pointer;
    }
    
    .margin-auto {
        margin: 0 auto;
    }

    .hidden-cont {
        overflow: hidden;
    }

    .animate-opacity {
        opacity: 0;
        animation: animateOpacity var(--trTime) forwards;
    }

    @keyframes animateOpacity {
        from {
            opacity: 0;
        }
        to {
            opacity: 1;
        }
    }
`

export default HelperClass
