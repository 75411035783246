import { forwardRef, useRef, useImperativeHandle } from 'react'
import Image from 'next/image'

//* Style
import ImageStyle from './style'

const CustomImage = forwardRef(({ src, alt, className, width, height, onMouseMove }, ref) => {
    const imgRef = useRef()

    useImperativeHandle(ref, () => imgRef.current, [])

    return (
        <ImageStyle
            onMouseMove={onMouseMove}
            style={{ width, height }}
            className={`image-cont ${className || ''}`}
            ref={imgRef}
        >
            <Image
                fill
                unoptimized={src && src.includes('.svg')}
                loading='lazy'
                src={src || '/images/default.jpeg'}
                alt={alt || 'image'}
                priority={false} //! Default false*/
                draggable={false}
            />
        </ImageStyle>
    )
})

CustomImage.displayName = 'CustomImage'

export default CustomImage
