import { createGlobalStyle } from 'styled-components'

const FormStyle = createGlobalStyle`
    :root {
        --inputPadding: var(--sp2-5x);
        --labelPosition: var(--sp2-5x);
        --errorExplainTop: var(--sp1x);
        --antFormItemMarginBottom: var(--sp13x);
        --registerFlowMaxWidth: var(--sp90x);
        --antSelectionItemPaddingLeft: var(--sp2-5x);
        --addonPaddingLeft: var(--sp2-5x);
        --antSelectHeight: var(--sp8x);
        --antSelectPlaceholder: var(--sp2-5x);
        --antSelectArrowRight: var(--sp2-5x);
        --eyeIconSizes: var(--sp3x);
	    --eyeIconRight: var(--sp2-5x);
        --selectDropdownOptionPadding: var(--sp2x);
        --multiplePlaceholderPaddingLeft: var(--sp1x);
        --multipleSelectSelectionPaddingLeft: var(--sp1-5x);
        --optionIconSizes: var(--sp2x);
        --multipleItemPadding: var(--sp1x) var(--sp1-5x);
        --multipleItemHeight: var(--sp5x);
        --removeIconSizes: var(--sp2x);
	    --lastFieldMarginBottom: var(--sp9x);
        --antRadioGroupMarginBottom: var(--sp2-5x);
	    --antRadioButtonPadding: var(--sp1x);
	    --antRadioButtonWrapperHeight: var(--sp6x);
	    --radioSelectTitle: var(--sp2-5x);
    }

    .avatar-error {
        margin-top: var(--sp1x);
    }

    .radio-select-title {
		margin-bottom: var(--radioSelectTitle);
	}

    .select-cutting {
        .ant-select-selection-item {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            max-width: 80%;
            width: 80px !important;
            display: block !important;
        }
    }
    
    .ant-radio-group {
		display: flex;
		background-color: var(--white);
		border-radius: var(--sp1x);
		margin-bottom: var(--antRadioGroupMarginBottom);
		padding: var(--antRadioButtonPadding);

		&.last-group {
			margin-bottom: unset;
		}

		.ant-radio-button-wrapper {
			display: flex;
			justify-content: center;
			align-items: center;
			width: 50%;
			height: var(--antRadioButtonWrapperHeight);

			.ant-radio-button {
				display: flex;
				text-align: center;
				border-radius: var(--sp1x);

				&.ant-radio-button-checked {
					background-color: var(--birodoGreen);

					& + span {
						color: var(--white);
					}
				}

				& + span {
					font-family: var(--plusJakartaSans);
					font-weight: 500;
					font-size: var(--p3);
					color: var(--maltedMint);
				}
			}
		}
	}

    .option-icon {
        font-size: var(--optionIconSizes);
    }

    .ant-select {
        .ant-select-selection-item {
            margin-top: 0;
            margin-bottom: 0;
            font-weight: 500 !important;

            .ant-select-selection-item-remove {
                display: flex;
                align-items: center;
                justify-content: center;

                .remove-icon {
                    font-size: var(--removeIconSizes);
                }
            }
        }
    }

    .ant-input-password-icon {
		position: absolute;
		right: var(--eyeIconRight);
		top: 50%;
		transform: translateY(-50%);
		font-size: var(--eyeIconSizes);
		margin-right: unset;
        cursor: pointer;

		&::before {
			color: var(--maltedMint);
		}
	}

    .ant-select-arrow {
        font-size: var(--createAccountIconSizes);
        right: var(--antSelectArrowRight);
    }

    .with-addon {
        .ant-input-wrapper {
            display: flex;
            flex-direction: row-reverse;

            .ant-input-affix-wrapper {
                flex: 1;
                border-radius: var(--sp1x);

                .ant-input {
                    border-left: unset !important;
                    transition: var(--trTime);
                }

                &.ant-input-affix-wrapper-focused {
                    & + .ant-input-group-addon {
                        border-color: var(--birodoGreen);
                        border-right: unset;
                    }
                }

                &.ant-input-affix-wrapper-status-error {
                    & + .ant-input-group-addon {
                        border-color: var(--obstinateOrange);
                    }
                }

                .ant-input {
                    border-radius: 0 var(--sp1x) var(--sp1x) 0;
                    padding-left: var(--sp0-5x);
                }
            }

            .ant-input-group-addon {
                width: unset;
                display: flex;
                align-items: center;
                background-color: var(--white);
                border-radius: var(--sp1x) 0 0 var(--sp1x);
                padding-right: unset;
                padding-left: var(--addonPaddingLeft);
                border: 2px solid transparent;
                border-right: unset;
                transition: var(--trTime);

                .addon-social {
                    font-family: var(--plusJakartaSans);
                    font-weight: 500;
                    font-size: var(--p3);
                    color: var(--birodoGreen);
                    font-size: 16px !important;
                }
            }
        }
    }


    .ant-form-item-control-input {
        & + div {
            display: unset !important;
        }
    }

    .ant-input {
        font-size: 16px !important;
    }

    .ant-form-item-explain {
        color: var(--obstinateOrange);
        font-family: var(--plusJakartaSans);
        height: unset;
        position: relative;
        top: var(--errorExplainTop);
        left: 0;
        font-size: var(--p4);
    }

    .ant-form-item {
        position: relative;
        margin-bottom: var(--antFormItemMarginBottom);

        &.last-field {
		    margin-bottom: var(--lastFieldMarginBottom);
	    }
        &.disabled {
            opacity: 50%;
            pointer-events: none;
        }

        &.hidden {
            height: 0;
            width: 0;
            margin: 0;
        }

        .ant-form-item-label {
            position: absolute;
            bottom: calc(100% + var(--labelPosition));
            left: 0;
            width: 100%;
            text-align: left;

            label {
                font-family: var(--plusJakartaSans);
                font-weight: 500;
                color: var(--birodoGreen);
                font-size: var(--p2);
                position: relative;

                &::after {
                    display: none !important;
                }
            }

            .ant-form-item-required {
                position: relative;

                &::before {
                    position: absolute;
                    top: 50%;
                    right: calc(var(--sp1-5x) * -1);
                    font-family: var(--plusJakartaSans) !important;
                    font-weight: 500;
                    font-size: var(--p2) !important;
                    margin-right: unset !important;
                    transform: translateY(-50%);
                }
            }
        }

        .ant-input-textarea {
            textarea {
                padding: var(--inputPadding);
                resize: none;
                border: none;
                background-color: var(--white);
                border: 2px solid transparent;
                border-radius: var(--sp1x);

                &:focus {
                    border: 2px solid var(--birodoGreen);
                }

                &::placeholder {
                    font-family: var(--plusJakartaSans);
                    font-weight: 500;
                    font-size: 16px !important;
                    color: var(--maltedMint);
                }
            }

            &.ant-input-textarea-status-error {
                .ant-input {
                    border: 2px solid var(--obstinateOrange);
                }
            }

            .ant-input {
                font-family: var(--plusJakartaSans);
                font-weight: 500;
                font-size: var(--p4);
                color: var(--birodoGreen);
            }

            .ant-input-textarea-suffix {
                display: none;
            }
        }

        .ant-input-affix-wrapper {
            display: block;
            height: 100%;
            border-radius: var(--sp1x);

            &.default {
                .ant-input-disabled {
                    background-color: var(--durianSmell);
                    color: var(--modernMint);
                }
            }

            &.lightGreen {
                .ant-input-disabled {
                    background-color: var(--clearlyAqua);
                    color: var(--modernMint);
                }
            }

            &.dirtyGreen {
                .ant-input-disabled {
                    background-color: var(--juniperBreeze);
                    color: var(--modernMint);
                }
            }

            .ant-input-suffix {
                .ant-form-item-feedback-icon {
                    display: none;
                }
            }

            &.ant-input-affix-wrapper-focused {
                .ant-input {
                    border: 2px solid var(--birodoGreen);
                }
            }

            &.ant-input-affix-wrapper-status-error {
                .ant-input {
                    border: 2px solid var(--obstinateOrange);
                }                
            }

            .ant-input {
                background-color: var(--white);
                padding: var(--inputPadding);
                border-radius: var(--sp1x);
                font-size: var(--p4);
                color: var(--birodoGreen);
                font-family: var(--plusJakartaSans);
                font-weight: 500;
                border: 2px solid transparent;

                &::placeholder {
                    color: var(--maltedMint);
                    font-family: var(--plusJakartaSans);
                    font-size: 16px !important;
                }
            }
        }

        .ant-input-affix-wrapper-disabled {
            .ant-input {
                background-color: var(--clearlyAqua);
                color: var(--modernMint);
            }
        }
    }

    .ant-select-item-option-content {
        font-size: var(--p3);
        font-weight: 500 !important;
        line-height: 1.3;
    }

    .ant-form-item {
        .ant-form-item-row {
            height: 100%;

            .ant-form-item-control {
                .ant-form-item-control-input {
                    height: 100%;

                    .ant-form-item-control-input-content {
                        height: 100%;

                        .ant-select {
                            height: 100%;
                            background-color: var(--white);
                            border-radius: var(--sp1x);
                            height: var(--antSelectHeight);
                            border: 2px solid transparent;

                            &.multiple {
                                .ant-select-selector {
                                    overflow: hidden;
                                }

                                .ant-select-selection-placeholder {
                                    padding-left: var(--multiplePlaceholderPaddingLeft) !important 
                                }

                                .ant-select-selection-overflow {
                                    padding-left: var(--multipleSelectSelectionPaddingLeft);
                                    font-size: 16px !important;
                                }

                                .ant-select-selection-item {
                                    padding: var(--multipleItemPadding) !important;
                                    display: flex;
                                    align-items: center;
                                    height: var(--multipleItemHeight);
                                    background-color: var(--cultured);
                                    color: var(--birodoGreen);

                                    .ant-select-selection-item-content {
                                        font-size: 16px !important;
                                        font-weight: 500;
                                        font-family: var(--plusJakartaSans);
                                        color: var(--birodoGreen);
                                    }
                                }
                            }

                            .ant-select-selector {
                                padding: unset;
                            }

                            .ant-select-selection-search-input {
                                color: var(--birodoGreen);
                            }

                            &.ant-select-status-error {
                                border-color: var(--obstinateOrange);
                            }

                            &.ant-select-focused {
                                border-color: var(--birodoGreen);
                            }

                            .ant-select-dropdown {
					            background-color: var(--white);
                                padding-left: var(--sp0-5x);
                                padding-right: var(--sp0-5x);

                                .ant-select-item {
                                    border-radius: var(--sp1x);
                                    padding: var(--selectDropdownOptionPadding);

                                    &.ant-select-item-option-selected {
                                        background-color: var(--cultured);

                                        .ant-select-item-option-content {
                                            font-weight: 400;
                                        }
                                    }
                                }
				            } 

                            .select-arrow {
                                &::before {
                                    color: var(--maltedMint);
                                }
                            }
                            
                            .ant-select-item-option-content {
                                text-transform: capitalize;
                                color: var(--birodoGreen);
                                font-family: var(--plusJakartaSans);
                            }

                            .ant-select-selector {
                                height: 100%;
                                display: flex;
                                align-items: center;

                                .ant-select-selection-placeholder {
                                    color: var(--maltedMint);
                                    padding-left: var(--antSelectPlaceholder);
                                    font-size: 16px !important;
                                    font-weight: 500;
                                    font-family: var(--plusJakartaSans);
                                }

                                .ant-select-selection-item {
                                    padding-left: var(--antSelectionItemPaddingLeft);
                                    display: flex;
                                    align-items: center;
                                    text-transform: capitalize;
                                    font-family: var(--plusJakartaSans);
                                    color: var(--birodoGreen);
                                    font-size: 16px !important;
                                    margin-right: var(--sp1x);
                                }

                                .ant-select-selection-search {
                                    .ant-select-selection-search-input {
                                        height: 100%;
                                        font-size: 16px !important;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    .ant-select-single {
        .ant-select-selection-search {
            .ant-select-selection-search-input {
                padding-left: var(--antSelectionItemPaddingLeft) !important;
                font-family: var(--plusJakartaSans) !important;
                color: var(--birodoGreen) !important;
                font-size: 16px !important;
            }
        }
    }

    @media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeXLMin}) and (min-width: ${(props) => props.theme.mediaQuery.DesktopSizeL}) {
        :root {
            --inputPadding: var(--sp2x);
            --labelPosition: var(--sp1x);
            --errorExplainTop: var(--sp1x);
            --antFormItemMarginBottom: var(--sp11x);
            --registerFlowMaxWidth: var(--sp68x);
            --antSelectionItemPaddingLeft: var(--sp2x);
            --addonPaddingLeft: var(--sp2x);
            --antSelectHeight: var(--sp7x);
            --antSelectPlaceholder: var(--sp2x);
            --antSelectArrowRight: var(--sp2x);
            --eyeIconSizes: var(--sp3x);
		    --eyeIconRight: var(--sp2x);
            --selectDropdownOptionPadding: var(--sp1-5x);
            --multiplePlaceholderPaddingLeft: var(--sp0-5x);
            --multipleSelectSelectionPaddingLeft: var(--sp1x);
            --optionIconSizes: var(--sp2x);
            --multipleItemPadding: var(--sp1x) var(--sp1-5x);
            --multipleItemHeight: var(--sp5x);
            --removeIconSizes: var(--sp2x);
		    --lastFieldMarginBottom: var(--sp7x);
            --antRadioGroupMarginBottom: var(--sp2x);
		    --antRadioButtonPadding: var(--sp1x);
		    --antRadioButtonWrapperHeight: var(--sp5x);
		    --radioSelectTitle: var(--sp2-5x);
        }
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeLMin}) and (min-width: ${(props) => props.theme.mediaQuery.DesktopSizeM}) {
        :root {
            --inputPadding: var(--sp2x);
            --labelPosition: var(--sp1x);
            --errorExplainTop: var(--sp1x);
            --antFormItemMarginBottom: var(--sp10x);
            --registerFlowMaxWidth: var(--sp58x);
            --antSelectionItemPaddingLeft: var(--sp2x);
            --addonPaddingLeft: var(--sp2x);
            --antSelectHeight: var(--sp7x);
            --antSelectPlaceholder: var(--sp2x);
            --antSelectArrowRight: var(--sp2x);
            --eyeIconSizes: var(--sp3x);
		    --eyeIconRight: var(--sp2x);
            --selectDropdownOptionPadding: var(--sp1-5x);
            --multiplePlaceholderPaddingLeft: var(--sp0-5x);
            --multipleSelectSelectionPaddingLeft: var(--sp1x);
            --optionIconSizes: var(--sp2x);
            --multipleItemPadding: var(--sp1x) var(--sp1-5x);
            --multipleItemHeight: var(--sp5x);
            --removeIconSizes: var(--sp1-5x);
		    --lastFieldMarginBottom: var(--sp6x);
            --antRadioGroupMarginBottom: var(--sp2x);
		    --antRadioButtonPadding: var(--sp1x);
		    --antRadioButtonWrapperHeight: var(--sp5x);
		    --radioSelectTitle: var(--sp2x);
        }
	}
    
	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeMMin}) and (min-width: ${(props) => props.theme.mediaQuery.DesktopSizeS}) {
        :root {
            --inputPadding: var(--sp2x);
            --labelPosition: var(--sp1x);
            --errorExplainTop: var(--sp1x);
            --antFormItemMarginBottom: var(--sp10x);
            --registerFlowMaxWidth: var(--sp58x);
            --antSelectionItemPaddingLeft: var(--sp2x);
            --addonPaddingLeft: var(--sp2x);
            --antSelectHeight: var(--sp7x);
            --antSelectPlaceholder: var(--sp2x);
            --antSelectArrowRight: var(--sp2x);
            --eyeIconSizes: var(--sp3x);
		    --eyeIconRight: var(--sp2x);
            --selectDropdownOptionPadding: var(--sp1-5x);
            --multiplePlaceholderPaddingLeft: var(--sp0-5x);
            --multipleSelectSelectionPaddingLeft: var(--sp1x);
            --optionIconSizes: var(--sp2x);
            --multipleItemPadding: var(--sp1x) var(--sp1-5x);
            --multipleItemHeight: var(--sp5x);
            --removeIconSizes: var(--sp1-5x);
		    --lastFieldMarginBottom: var(--sp6x);
            --antRadioGroupMarginBottom: var(--sp2x);
		    --antRadioButtonPadding: var(--sp1x);
		    --antRadioButtonWrapperHeight: var(--sp5x);
		    --radioSelectTitle: var(--sp2x);
        }
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeSMin}) and (min-width: ${(props) => props.theme.mediaQuery.DesktopSizeXS}) {
        :root {
            --inputPadding: var(--sp2x);
            --labelPosition: var(--sp0-5x);
            --errorExplainTop: var(--sp0-5x);
            --antFormItemMarginBottom: var(--sp8x);
            --registerFlowMaxWidth: var(--sp46x);
            --antSelectionItemPaddingLeft: var(--sp2x);
            --addonPaddingLeft: var(--sp2x);
            --antSelectHeight: var(--sp6x);
            --antSelectPlaceholder: var(--sp2x);
            --antSelectArrowRight: var(--sp2x);
            --eyeIconSizes: var(--sp2-5x);
		    --eyeIconRight: var(--sp2x);
            --selectDropdownOptionPadding: var(--sp1-5x);
            --multiplePlaceholderPaddingLeft: var(--sp0-5x);
            --multipleSelectSelectionPaddingLeft: var(--sp1x);
            --optionIconSizes: var(--sp2x);
            --multipleItemPadding: var(--sp1x) var(--sp1-5x);
            --multipleItemHeight: var(--sp4x);
            --removeIconSizes: var(--sp1-5x);
		    --lastFieldMarginBottom: var(--sp5x);
            --antRadioGroupMarginBottom: var(--sp2x);
		    --antRadioButtonPadding: var(--sp1x);
		    --antRadioButtonWrapperHeight: var(--sp4x);
            --radioSelectTitle: var(--sp2x);
        }
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeXSMin}) and (min-width: ${(props) => props.theme.mediaQuery.TabletSize}) {
        :root {
            --inputPadding: var(--sp1-5x);
            --labelPosition: 0%;
            --errorExplainTop: var(--sp0-5x);
            --antFormItemMarginBottom: var(--sp7x);
            --registerFlowMaxWidth: var(--sp51x);
            --antSelectionItemPaddingLeft: var(--sp1-5x);
            --addonPaddingLeft: var(--sp1-5x);
            --antSelectHeight: var(--sp5x);
            --antSelectPlaceholder: var(--sp1-5x);
            --antSelectArrowRight: var(--sp1-5x);
            --eyeIconSizes: var(--sp2x);
		    --eyeIconRight: var(--sp1-5x);
            --selectDropdownOptionPadding: var(--sp1x) var(--sp1-5x);
            --multiplePlaceholderPaddingLeft: 0;
            --multipleSelectSelectionPaddingLeft: var(--sp0-5x);
            --multipleItemPadding: var(--sp1x) var(--sp1-5x);
            --multipleItemHeight: var(--sp4x);
            --removeIconSizes: var(--sp1-5x);
		    --lastFieldMarginBottom: var(--sp5x);
            --antRadioGroupMarginBottom: var(--sp2x);
		    --antRadioButtonPadding: var(--sp0-5x);
		    --antRadioButtonWrapperHeight: var(--sp4x);
		    --radioSelectTitle: var(--sp1-5x);
        }
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.TabletSizeMin}) and (min-width: ${(props) => props.theme.mediaQuery.TabletSizeS}) {
        :root {
            --inputPadding: var(--sp1-5x);
            --labelPosition: 0%;
            --errorExplainTop: var(--sp0-5x);
            --antFormItemMarginBottom: var(--sp7x);
            --registerFlowMaxWidth: var(--sp51x);
            --antSelectionItemPaddingLeft: var(--sp1-5x);
            --addonPaddingLeft: var(--sp1-5x);
            --antSelectHeight: var(--sp5x);
            --antSelectPlaceholder: var(--sp1-5x);
            --antSelectArrowRight: var(--sp1-5x);
            --eyeIconSizes: var(--sp2x);
		    --eyeIconRight: var(--sp1-5x);
            --selectDropdownOptionPadding: var(--sp1x) var(--sp1-5x);
            --multiplePlaceholderPaddingLeft: 0;
            --multipleSelectSelectionPaddingLeft: var(--sp0-5x);
            --optionIconSizes: var(--sp2x);
            --multipleItemPadding: var(--sp1x) var(--sp1-5x);
            --multipleItemHeight: var(--sp4x);
            --removeIconSizes: var(--sp1-5x);
		    --lastFieldMarginBottom: var(--sp5x);
            --antRadioGroupMarginBottom: var(--sp1-5x);
		    --antRadioButtonPadding: var(--sp0-5x);
		    --antRadioButtonWrapperHeight: var(--sp4x);
		    --radioSelectTitle: var(--sp1-5x);
        }
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.TabletSizeSMin}) {
        :root {
            --inputPadding: var(--sp1-5x);
            --labelPosition: 0%;
            --errorExplainTop: var(--sp0-5x);
            --antFormItemMarginBottom: var(--sp6x);
            --registerFlowMaxWidth: 100%;
            --antSelectionItemPaddingLeft: var(--sp1-5x);
            --addonPaddingLeft: var(--sp1-5x);
            --antSelectHeight: var(--sp5x);
            --antSelectPlaceholder: var(--sp1-5x);
            --antSelectArrowRight: var(--sp1-5x);
            --eyeIconSizes: var(--sp2x);
		    --eyeIconRight: var(--sp1-5x);
            --selectDropdownOptionPadding: var(--sp1x) var(--sp1-5x);
            --multiplePlaceholderPaddingLeft: 0;
            --multipleSelectSelectionPaddingLeft: var(--sp0-5x);
            --optionIconSizes: var(--sp1-5x);
            --multipleItemPadding: var(--sp1x) var(--sp1-5x);
            --multipleItemHeight: var(--sp4x);
            --removeIconSizes: var(--sp1-5x);
		    --lastFieldMarginBottom: var(--sp4x);
            --antRadioGroupMarginBottom: var(--sp1-5x);
		    --antRadioButtonPadding: var(--sp0-5x);
		    --antRadioButtonWrapperHeight: var(--sp4x);
    		--radioSelectTitle: var(--sp1-5x);
        }	

        .ant-radio-button-wrapper {
            padding: 0 var(--sp0-5x);
        }
    }
`

export default FormStyle
