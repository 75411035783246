import { createGlobalStyle } from 'styled-components'
import localFont from '@next/font/local'
import theme from './theme'

const plusJakartaSans = localFont({
    src: [
        {
            path: '../public/fonts/PlusJakartaSans-Regular.woff',
            weight: '400',
            display: 'swap',
            style: 'normal',
        },
        {
            path: '../public/fonts/PlusJakartaSans-Medium.woff',
            weight: '500',
            display: 'swap',
            style: 'normal',
        },
        {
            path: '../public/fonts/PlusJakartaSans-SemiBold.woff',
            weight: '600',
            display: 'swap',
            style: 'normal',
        },
        {
            path: '../public/fonts/PlusJakartaSans-Bold.woff',
            weight: '700',
            display: 'swap',
            style: 'normal',
        },
    ],
})

const recklessNeue = localFont({
    src: [
        {
            path: '../public/fonts/RecklessNeue-Medium.woff',
            weight: '500',
            display: 'swap',
            style: 'normal',
        },
        {
            path: '../public/fonts/RecklessNeue-SemiBold.woff',
            weight: '600',
            display: 'swap',
            style: 'normal',
        },
    ],
})

function spaceGenerator() {
    let str = ''

    for (let i = 1; i <= 250; i++) {
        str += `--sp${i}x: calc(var(--spSize) * ${i});`
    }

    return str
}

function colorVariablesGenerator() {
    const colorsArr = Object.keys(theme.colors)

    let colorVariable = ''

    for (let i = 0; i < colorsArr.length; i++) {
        colorVariable += `--${colorsArr[i]}: ${theme.colors[colorsArr[i]]};`
    }

    return colorVariable
}

const Variables = createGlobalStyle`
    :root {
        //! Fonts
        --plusJakartaSans: ${plusJakartaSans.style.fontFamily};
        --recklessNeue: ${recklessNeue.style.fontFamily};
        
        //! Screen Sizes
        --desktopSizeXL:${(props) => props.theme.mediaQuery.DesktopSizeXL};
        --desktopSizeL:${(props) => props.theme.mediaQuery.DesktopSizeL};
        --desktopSizeM:${(props) => props.theme.mediaQuery.DesktopSizeM};
        --desktopSizeS: ${(props) => props.theme.mediaQuery.DesktopSizeS};
        --desktopSizeXS: ${(props) => props.theme.mediaQuery.DesktopSizeXS};
        --tabletSize: ${(props) => props.theme.mediaQuery.TabletSize};
        --tabletSizeS: ${(props) => props.theme.mediaQuery.TabletSizeS};
        --mobileSize: ${(props) => props.theme.mediaQuery.MobileSize};
        --mobileSizeS: ${(props) => props.theme.mediaQuery.MobileSizeS};

        //! Spacing
        --spSize: 8px;

        --sp0-5x: calc(var(--spSize) * 0.5);
        --sp1-5x: calc(var(--spSize) * 1.5);
        --sp2-5x: calc(var(--spSize) * 2.5);
        --sp3-5x: calc(var(--spSize) * 3.5);
        ${spaceGenerator()}

        //! Transition
        --trTime: 0.3s;

        //! Line heights
        --lineHeightXS: 1.1;
        --lineHeightS: 1.2;
        --lineHeightM: 1.3;
        --lineHeightL: 1.4;
        --lineHeightXL: 1.6;

        //! Colors
        ${colorVariablesGenerator()}
    }

    @media only screen and  (min-width: ${(props) => props.theme.mediaQuery.DesktopSizeXL}) { 
        :root {
            --h1: 176px;
            --h2: 136px;
            --h3: 88px;
            --h4: 64px;
            --h5: 56px;
            --h6: 40px;
            --p1: 32px;
            --p2: 24px;
            --p3: 20px;
            --p4: 16px;

            //! Line heights
                //! Font Plus Jakarta Sans
                --lineHeightH1FontJakarta: var(--lineHeightM);
                --lineHeightH2FontJakarta: var(--lineHeightM);
                --lineHeightH3FontJakarta: var(--lineHeightM);
                --lineHeightH4FontJakarta: var(--lineHeightM);
                --lineHeightH5FontJakarta: var(--lineHeightM); 
                --lineHeightH6FontJakarta: var(--lineHeightM);
                --lineHeightP1FontJakarta: var(--lineHeightL);
                --lineHeightP2FontJakarta: var(--lineHeightXL);
                --lineHeightP3FontJakarta: var(--lineHeightXL);
                --lineHeightP4FontJakarta: var(--lineHeightXL);

                //! Font Reckless Neue
                --lineHeightH1FontReckless: var(--lineHeightXS);
                --lineHeightH2FontReckless: var(--lineHeightXS);
                --lineHeightH3FontReckless: var(--lineHeightS);
                --lineHeightH4FontReckless: var(--lineHeightS);
                --lineHeightH5FontReckless: var(--lineHeightS); 
                --lineHeightH6FontReckless: var(--lineHeightS);
                --lineHeightP1FontReckless: var(--lineHeightM);
                --lineHeightP2FontReckless: var(--lineHeightM);
                --lineHeightP3FontReckless: var(--lineHeightM);
                --lineHeightP4FontReckless: var(--lineHeightM);

            //! Global paddings
            --contPaddingLR: var(--sp20x);
            --colPadding: var(--sp5x);
            --sectionDistance: var(--sp30x);
            --subsectionsPadding: var(--sp19x);
            --titlePictureBackgroundPadding: var(--sp12x);
            --titleTextIllustrationLightBgPad: var(--sp9x);
            --illustrationTitleDarkBgPad: var(--sp13x);
            --headerContentPadding: var(--sp9x);
            --filterContentPadding: var(--sp9x); 
            --miniIconSizes: var(--sp2x);
            --mapIconSizes: var(--sp4x); 
            --eventIndividualIconSizes: var(--sp4x);
            --createAccountIconSizes: var(--sp3x);
            --rotationRejectionDescriptionMaxWidth: var(--sp56x);

            //! Global border size
            --globalBorderSize: var(--sp4x);
        }
    }

    @media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeXLMin}) and (min-width: ${(props) => props.theme.mediaQuery.DesktopSizeL}) { 
        :root {
            --h1: 136px;
            --h2: 104px;
            --h3: 64px;
            --h4: 48px;
            --h5: 40px;
            --h6: 32px;
            --p1: 24px;
            --p2: 20px;
            --p3: 16px;
            --p4: 14px;

            //! Line heights
                //! Font Plus Jakarta Sans
                --lineHeightH1FontJakarta: var(--lineHeightM);
                --lineHeightH2FontJakarta: var(--lineHeightM);
                --lineHeightH3FontJakarta: var(--lineHeightM);
                --lineHeightH4FontJakarta: var(--lineHeightM);
                --lineHeightH5FontJakarta: var(--lineHeightM); 
                --lineHeightH6FontJakarta: var(--lineHeightL);
                --lineHeightP1FontJakarta: var(--lineHeightXL);
                --lineHeightP2FontJakarta: var(--lineHeightXL);
                --lineHeightP3FontJakarta: var(--lineHeightXL);
                --lineHeightP4FontJakarta: var(--lineHeightXL);

                //! Font Reckless Neue
                --lineHeightH1FontReckless: var(--lineHeightXS);
                --lineHeightH2FontReckless: var(--lineHeightXS);
                --lineHeightH3FontReckless: var(--lineHeightS);
                --lineHeightH4FontReckless: var(--lineHeightS);
                --lineHeightH5FontReckless: var(--lineHeightS); 
                --lineHeightH6FontReckless: var(--lineHeightM);
                --lineHeightP1FontReckless: var(--lineHeightM);
                --lineHeightP2FontReckless: var(--lineHeightM);
                --lineHeightP3FontReckless: var(--lineHeightM);
                --lineHeightP4FontReckless: var(--lineHeightM);

            //! Global paddings
            --contPaddingLR: var(--sp14x);
            --colPadding: var(--sp4x);
            --sectionDistance: var(--sp25x);
            --subsectionsPadding: var(--sp15x);
            --titlePictureBackgroundPadding: var(--sp9x);
            --titleTextIllustrationLightBgPad: var(--sp7x);
            --illustrationTitleDarkBgPad: var(--sp9x);
            --headerContentPadding: var(--sp6x);            
            --filterContentPadding: var(--sp6x); 
            --miniIconSizes: var(--sp2x);
            --mapIconSizes: var(--sp3x); 
            --eventIndividualIconSizes: var(--sp3x);
            --createAccountIconSizes: var(--sp3x);
            --rotationRejectionDescriptionMaxWidth: var(--sp56x);
            
            //! Global border size
            --globalBorderSize: var(--sp4x);
        }
    }

    @media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeLMin}) and (min-width: ${(props) => props.theme.mediaQuery.DesktopSizeM}) { 
        :root {
            --h1: 96px;
            --h2: 80px;
            --h3: 48px;
            --h4: 40px;
            --h5: 32px;
            --h6: 24px;
            --p1: 20px;
            --p2: 18px;
            --p3: 16px;
            --p4: 14px;

            //! Line heights
                //! Font Plus Jakarta Sans
                --lineHeightH1FontJakarta: var(--lineHeightM);
                --lineHeightH2FontJakarta: var(--lineHeightM);
                --lineHeightH3FontJakarta: var(--lineHeightM);
                --lineHeightH4FontJakarta: var(--lineHeightM);
                --lineHeightH5FontJakarta: var(--lineHeightL); 
                --lineHeightH6FontJakarta: var(--lineHeightXL);
                --lineHeightP1FontJakarta: var(--lineHeightXL);
                --lineHeightP2FontJakarta: var(--lineHeightXL);
                --lineHeightP3FontJakarta: var(--lineHeightXL);
                --lineHeightP4FontJakarta: var(--lineHeightXL);

                //! Font Reckless Neue
                --lineHeightH1FontReckless: var(--lineHeightXS);
                --lineHeightH2FontReckless: var(--lineHeightS);
                --lineHeightH3FontReckless: var(--lineHeightS);
                --lineHeightH4FontReckless: var(--lineHeightS);
                --lineHeightH5FontReckless: var(--lineHeightM); 
                --lineHeightH6FontReckless: var(--lineHeightM);
                --lineHeightP1FontReckless: var(--lineHeightM);
                --lineHeightP2FontReckless: var(--lineHeightM);
                --lineHeightP3FontReckless: var(--lineHeightM);
                --lineHeightP4FontReckless: var(--lineHeightM);

            //! Global paddings
            --contPaddingLR: var(--sp13x);
            --colPadding: var(--sp3x);
            --sectionDistance: var(--sp20x);
            --subsectionsPadding: var(--sp11x);
            --titlePictureBackgroundPadding: var(--sp7x);
            --titleTextIllustrationLightBgPad: var(--sp6x);
            --illustrationTitleDarkBgPad: var(--sp8x);
            --headerContentPadding: var(--sp5x);            
            --filterContentPadding: var(--sp5x); 
            --miniIconSizes: var(--sp2x);
            --mapIconSizes: var(--sp2-5x); 
            --eventIndividualIconSizes: var(--sp2-5x);
            --createAccountIconSizes: var(--sp3x);
            --rotationRejectionDescriptionMaxWidth: var(--sp56x);

            //! Global border size
            --globalBorderSize: var(--sp2-5x);
        }
    }

    @media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeMMin}) and (min-width: ${(props) => props.theme.mediaQuery.DesktopSizeS}) { 
        :root {
            --h1: 96px;
            --h2: 80px;
            --h3: 48px;
            --h4: 40px;
            --h5: 32px;
            --h6: 24px;
            --p1: 20px;
            --p2: 18px;
            --p3: 16px;
            --p4: 14px;

            //! Line heights
                //! Font Plus Jakarta Sans
                --lineHeightH1FontJakarta: var(--lineHeightM);
                --lineHeightH2FontJakarta: var(--lineHeightM);
                --lineHeightH3FontJakarta: var(--lineHeightM);
                --lineHeightH4FontJakarta: var(--lineHeightM);
                --lineHeightH5FontJakarta: var(--lineHeightL); 
                --lineHeightH6FontJakarta: var(--lineHeightXL);
                --lineHeightP1FontJakarta: var(--lineHeightXL);
                --lineHeightP2FontJakarta: var(--lineHeightXL);
                --lineHeightP3FontJakarta: var(--lineHeightXL);
                --lineHeightP4FontJakarta: var(--lineHeightXL);

                //! Font Reckless Neue
                --lineHeightH1FontReckless: var(--lineHeightXS);
                --lineHeightH2FontReckless: var(--lineHeightS);
                --lineHeightH3FontReckless: var(--lineHeightS);
                --lineHeightH4FontReckless: var(--lineHeightS);
                --lineHeightH5FontReckless: var(--lineHeightM); 
                --lineHeightH6FontReckless: var(--lineHeightM);
                --lineHeightP1FontReckless: var(--lineHeightM);
                --lineHeightP2FontReckless: var(--lineHeightM);
                --lineHeightP3FontReckless: var(--lineHeightM);
                --lineHeightP4FontReckless: var(--lineHeightM);

            //! Global paddings
            --contPaddingLR: var(--sp12x);
            --colPadding: var(--sp3x);
            --sectionDistance: var(--sp20x);
            --subsectionsPadding: var(--sp11x);
            --titlePictureBackgroundPadding: var(--sp7x);
            --titleTextIllustrationLightBgPad: var(--sp6x);
            --illustrationTitleDarkBgPad: var(--sp8x);
            --headerContentPadding: var(--sp5x);            
            --filterContentPadding: var(--sp5x); 
            --miniIconSizes: var(--sp2x);
            --mapIconSizes: var(--sp2-5x); 
            --eventIndividualIconSizes: var(--sp2-5x);
            --createAccountIconSizes: var(--sp3x);
            --rotationRejectionDescriptionMaxWidth: var(--sp56x);

            //! Global border size
            --globalBorderSize: var(--sp2-5x);
        }
    }

    @media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeSMin}) and (min-width: ${(props) => props.theme.mediaQuery.DesktopSizeXS}) {
        :root {
            --h1: 80px;
            --h2: 64px;
            --h3: 40px;
            --h4: 32px;
            --h5: 24px;
            --h6: 20px;
            --p1: 18px;
            --p2: 16px;
            --p3: 14px;
            --p4: 14px;

            //! Line heights
                //! Font Plus Jakarta Sans
                --lineHeightH1FontJakarta: var(--lineHeightM);
                --lineHeightH2FontJakarta: var(--lineHeightM);
                --lineHeightH3FontJakarta: var(--lineHeightM);
                --lineHeightH4FontJakarta: var(--lineHeightL);
                --lineHeightH5FontJakarta: var(--lineHeightXL); 
                --lineHeightH6FontJakarta: var(--lineHeightXL);
                --lineHeightP1FontJakarta: var(--lineHeightXL);
                --lineHeightP2FontJakarta: var(--lineHeightXL);
                --lineHeightP3FontJakarta: var(--lineHeightXL);
                --lineHeightP4FontJakarta: var(--lineHeightXL);

                //! Font Reckless Neue
                --lineHeightH1FontReckless: var(--lineHeightS);
                --lineHeightH2FontReckless: var(--lineHeightS);
                --lineHeightH3FontReckless: var(--lineHeightS);
                --lineHeightH4FontReckless: var(--lineHeightM);
                --lineHeightH5FontReckless: var(--lineHeightM); 
                --lineHeightH6FontReckless: var(--lineHeightM);
                --lineHeightP1FontReckless: var(--lineHeightM);
                --lineHeightP2FontReckless: var(--lineHeightM);
                --lineHeightP3FontReckless: var(--lineHeightM);
                --lineHeightP4FontReckless: var(--lineHeightM);

            //! Global paddings
            --contPaddingLR: var(--sp8x);
            --colPadding: var(--sp3x);
            --sectionDistance: var(--sp17x);
            --subsectionsPadding: var(--sp9x);
            --titlePictureBackgroundPadding: var(--sp6x);
            --titleTextIllustrationLightBgPad: var(--sp5x);
            --illustrationTitleDarkBgPad: var(--sp7x);
            --headerContentPadding: var(--sp4x);            
            --filterContentPadding: var(--sp4x); 
            --miniIconSizes: var(--sp1-5x);
            --mapIconSizes: var(--sp2-5x); 
            --eventIndividualIconSizes: var(--sp2-5x);
            --createAccountIconSizes: var(--sp2-5x);
            --rotationRejectionDescriptionMaxWidth: var(--sp56x);
            
            //! Global border size
            --globalBorderSize: var(--sp2-5x);
        }
    }

    @media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeXSMin}) and (min-width: ${(props) => props.theme.mediaQuery.TabletSize}) { 
        :root {
            --h1: 64px;
            --h2: 48px;
            --h3: 32px;
            --h4: 24px;
            --h5: 20px;
            --h6: 20px;
            --p1: 18px;
            --p2: 16px;
            --p3: 14px;
            --p4: 14px;

            //! Line heights
                //! Font Plus Jakarta Sans
                --lineHeightH1FontJakarta: var(--lineHeightM);
                --lineHeightH2FontJakarta: var(--lineHeightM);
                --lineHeightH3FontJakarta: var(--lineHeightL);
                --lineHeightH4FontJakarta: var(--lineHeightXL);
                --lineHeightH5FontJakarta: var(--lineHeightXL); 
                --lineHeightH6FontJakarta: var(--lineHeightXL);
                --lineHeightP1FontJakarta: var(--lineHeightXL);
                --lineHeightP2FontJakarta: var(--lineHeightXL);
                --lineHeightP3FontJakarta: var(--lineHeightXL);
                --lineHeightP4FontJakarta: var(--lineHeightXL);

                //! Font Reckless Neue
                --lineHeightH1FontReckless: var(--lineHeightS);
                --lineHeightH2FontReckless: var(--lineHeightS);
                --lineHeightH3FontReckless: var(--lineHeightM);
                --lineHeightH4FontReckless: var(--lineHeightM);
                --lineHeightH5FontReckless: var(--lineHeightM); 
                --lineHeightH6FontReckless: var(--lineHeightM);
                --lineHeightP1FontReckless: var(--lineHeightM);
                --lineHeightP2FontReckless: var(--lineHeightM);
                --lineHeightP3FontReckless: var(--lineHeightM);
                --lineHeightP4FontReckless: var(--lineHeightM);

            //! Global paddings
            --contPaddingLR: var(--sp4x);
            --colPadding: var(--sp3x);
            --sectionDistance: var(--sp12x);
            --subsectionsPadding: var(--sp7x);
            --titlePictureBackgroundPadding: var(--sp5x);
            --titleTextIllustrationLightBgPad: var(--sp4x);
            --illustrationTitleDarkBgPad: var(--sp6x);
            --headerContentPadding: var(--sp3x);            
            --filterContentPadding: var(--sp3x); 
            --miniIconSizes: var(--sp1-5x);
            --mapIconSizes: var(--sp2-5x); 
            --eventIndividualIconSizes: var(--sp2-5x);
            --createAccountIconSizes: var(--sp2x);
            --rotationRejectionDescriptionMaxWidth: var(--sp56x);
            
            //! Global border size
            --globalBorderSize: var(--sp2x);
        }
    }

    @media only screen and (max-width: ${(props) => props.theme.mediaQuery.TabletSizeMin}) and (min-width: ${(props) => props.theme.mediaQuery.TabletSizeS}) { 
        :root {
            --h1: 64px;
            --h2: 48px;
            --h3: 32px;
            --h4: 24px;
            --h5: 20px;
            --h6: 20px;
            --p1: 18px;
            --p2: 16px;
            --p3: 14px;
            --p4: 14px;

            //! Line heights
                //! Font Plus Jakarta Sans
                --lineHeightH1FontJakarta: var(--lineHeightM);
                --lineHeightH2FontJakarta: var(--lineHeightM);
                --lineHeightH3FontJakarta: var(--lineHeightL);
                --lineHeightH4FontJakarta: var(--lineHeightXL);
                --lineHeightH5FontJakarta: var(--lineHeightXL); 
                --lineHeightH6FontJakarta: var(--lineHeightXL);
                --lineHeightP1FontJakarta: var(--lineHeightXL);
                --lineHeightP2FontJakarta: var(--lineHeightXL);
                --lineHeightP3FontJakarta: var(--lineHeightXL);
                --lineHeightP4FontJakarta: var(--lineHeightXL);

                //! Font Reckless Neue
                --lineHeightH1FontReckless: var(--lineHeightS);
                --lineHeightH2FontReckless: var(--lineHeightS);
                --lineHeightH3FontReckless: var(--lineHeightM);
                --lineHeightH4FontReckless: var(--lineHeightM);
                --lineHeightH5FontReckless: var(--lineHeightM); 
                --lineHeightH6FontReckless: var(--lineHeightM);
                --lineHeightP1FontReckless: var(--lineHeightM);
                --lineHeightP2FontReckless: var(--lineHeightM);
                --lineHeightP3FontReckless: var(--lineHeightM);
                --lineHeightP4FontReckless: var(--lineHeightM);

            //! Global paddings
            --contPaddingLR: var(--sp3x);
            --colPadding: var(--sp3x);
            --sectionDistance: var(--sp12x);
            --subsectionsPadding: var(--sp7x);
            --titlePictureBackgroundPadding: var(--sp5x);
            --titleTextIllustrationLightBgPad: var(--sp4x);
            --illustrationTitleDarkBgPad: var(--sp6x);
            --headerContentPadding: var(--sp3x);            
            --filterContentPadding: var(--sp3x); 
            --miniIconSizes: var(--sp1-5x);
            --mapIconSizes: var(--sp2-5x); 
            --eventIndividualIconSizes: var(--sp2-5x);
            --createAccountIconSizes: var(--sp2x);
            --rotationRejectionDescriptionMaxWidth: var(--sp43x);

            //! Global border size
            --globalBorderSize: var(--sp2x);
        }
    }

    @media only screen and (max-width: ${(props) => props.theme.mediaQuery.TabletSizeSMin}) { 
        :root {
            --h1: 56px;
            --h2: 40px;
            --h3: 24px;
            --h4: 20px;
            --h5: 18px;
            --h6: 18px;
            --p1: 16px;
            --p2: 14px;
            --p3: 14px;
            --p4: 14px;

            //! Line heights
                //! Font Plus Jakarta Sans
                --lineHeightH1FontJakarta: var(--lineHeightM);
                --lineHeightH2FontJakarta: var(--lineHeightM);
                --lineHeightH3FontJakarta: var(--lineHeightXL);
                --lineHeightH4FontJakarta: var(--lineHeightXL);
                --lineHeightH5FontJakarta: var(--lineHeightXL); 
                --lineHeightH6FontJakarta: var(--lineHeightXL);
                --lineHeightP1FontJakarta: var(--lineHeightXL);
                --lineHeightP2FontJakarta: var(--lineHeightXL);
                --lineHeightP3FontJakarta: var(--lineHeightXL);
                --lineHeightP4FontJakarta: var(--lineHeightXL);

                //! Font Reckless Neue
                --lineHeightH1FontReckless: var(--lineHeightS);
                --lineHeightH2FontReckless: var(--lineHeightS);
                --lineHeightH3FontReckless: var(--lineHeightM);
                --lineHeightH4FontReckless: var(--lineHeightM);
                --lineHeightH5FontReckless: var(--lineHeightM); 
                --lineHeightH6FontReckless: var(--lineHeightM);
                --lineHeightP1FontReckless: var(--lineHeightM);
                --lineHeightP2FontReckless: var(--lineHeightM);
                --lineHeightP3FontReckless: var(--lineHeightM);
                --lineHeightP4FontReckless: var(--lineHeightM);

            //! Global paddings
            --contPaddingLR: var(--sp2x);
            --colPadding: var(--sp2x);
            --sectionDistance: var(--sp9x);
            --subsectionsPadding: var(--sp5x);
            --titlePictureBackgroundPadding: var(--sp4x);
            --titleTextIllustrationLightBgPad: var(--sp3x);
            --illustrationTitleDarkBgPad: var(--sp4x);
            --headerContentPadding: var(--sp3x);            
            --filterContentPadding: var(--sp3x); 
            --miniIconSizes: var(--sp1-5x);
            --mapIconSizes: var(--sp2-5x); 
            --eventIndividualIconSizes: var(--sp2-5x);
            --createAccountIconSizes: var(--sp2x);
            --rotationRejectionDescriptionMaxWidth: var(--sp56x);

            //! Global border size
            --globalBorderSize: var(--sp2x);
        }
    }
`

export default Variables
