import { useImperativeHandle, useState, useCallback, forwardRef, useRef } from 'react'

//* Component's
import { Form } from 'antd'
import { useForm } from 'antd/lib/form/Form'
import GlobalError from 'components/common/GlobalError'

const FormContainer = forwardRef(({ layout, className, resetFields = true, ...props }, ref) => {
    //! Form
    const [form] = useForm()

    //! Send Form as Ref
    useImperativeHandle(ref, () => {
        return form
    })

    //! State
    const [error, setError] = useState()

    //! On Value Change
    const onValuesChange = (newVal, values) => {
        props.onChange && props.onChange(newVal, values, form)
    }
    //! Finish failed
    const onFinishFailed = ({ errorFields }) => {
        form.scrollToField(errorFields[0].name, {
            block: 'center',
            inline: 'center',
        })
    }

    //! On Finish
    const onFinish = useCallback(
        (values) => {
            form.setFields([])
            setError('')

            props
                .onFinish(values)
                .then((res) => {
                    resetFields && form.resetFields()
                    Promise.resolve(res)
                })
                .catch((err) => {

                    if (err?.data) {
                        const formValues = []

                        if (typeof err.data.message === 'string') {
                            props.setError ? props.setError(err.data.message) : setError(err.data.message)
                        }

                        for (let key in values) {
                            formValues.push({
                                name: key,
                                value: values[key],
                                errors: [err.data.errors?.[key]]?.[0],
                            })
                        }

                        form.setFields(formValues)
                    }

                    Promise.reject(err?.data)
                })
        },
        [props, form, error]
    )

    const formRef = useRef()

    return (
        <div className='form-wrapper' ref={formRef}>
            {error && <GlobalError hasMarginBottom={true} error={error} />}

            <Form
                layout={layout || ''}
                initialValues={props.initialValues}
                onFinish={onFinish}
                onValuesChange={onValuesChange}
                onFinishFailed={onFinishFailed}
                form={form}
                className={className || ''}
            >
                {props.children}
            </Form>
        </div>
    )
})

FormContainer.displayName = 'FormContainer'

export default FormContainer
