import { memo as Memo, useEffect, useMemo, useCallback } from 'react'

//* HOC's
import withUIContext from 'context/consumerHOC/UIConsumer'

//* Component's
import Icon from 'components/common/Icon'

//* Style
import PopupStyle from './style'

const Popup = Memo(({ closePopup, popupIsOpen, popupComponent, popupProps, popupLoading }) => {
    const components = useMemo(() => ({}), [])

    const Child = useMemo(() => typeof popupComponent === 'string' && components[popupComponent] && components[popupComponent], [popupComponent])

    useEffect(() => {
        window.addEventListener('keydown', handleKeydown)

        if ('virtualKeyboard' in navigator) {
            navigator.virtualKeyboard.overlaysContent = true
        }

        return () => {
            window.removeEventListener('keydown', handleKeydown)
        }
    }, [])

    const handleKeydown = useCallback((e) => {
        if (e.key === 'Escape') {
            closePopup()
        }
    }, [])

    return popupIsOpen ? (
        <PopupStyle className={`${popupProps.className || ''} popup-container ${popupLoading ? 'popup-loading' : ''}`}>
            <div
                className={`popup-background`}
                onClick={closePopup}
            />

            <div className={`popup-content-container`}>
                <div className={`popup-content-wrap`}>
                    <div className='inner-child'>{typeof popupComponent === 'string' ? <Child /> : popupComponent}</div>

                    <div
                        className={'close-wrap'}
                        onClick={closePopup}
                    >
                        <Icon name={`cross`} />
                    </div>
                </div>
            </div>
        </PopupStyle>
    ) : null
})

export default withUIContext(Popup, ['closePopup', 'popupIsOpen', 'popupComponent', 'popupProps', 'popupLoading'])
