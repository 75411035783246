import { createContext, Component } from 'react'
import { withRouter } from 'next/router'

import { api, config, setCookie, removeCookie, asyncFunctionDecorator, checkUserRedirection, getUser } from 'helpers'
import { objectToFormData } from 'helpers/_objectToFormData'

const UserContext = createContext(null)

class UserProvider extends Component {
    state = {
        prevRoute: '',
        ...this.props.userProps,
    }

    methods = {
        login: this.login.bind(this),
        forgotPassword: this.forgotPassword.bind(this),
        resetPassword: this.resetPassword.bind(this),
        register: this.register.bind(this),
        resendVerifyCode: this.resendVerifyCode.bind(this),
        verifyAccount: this.verifyAccount.bind(this),
        updatePassword: this.updatePassword.bind(this),
        updateAccount: this.updateAccount.bind(this),
        logout: this.logout.bind(this),
        handleFirstStep: this.handleFirstStep.bind(this),
        handleSecondStep: this.handleSecondStep.bind(this),
        handleThirdStep: this.handleThirdStep.bind(this),
        accountInfo: this.accountInfo.bind(this),
        getUserInfo: this.getUserInfo.bind(this),
        removeAccount: this.removeAccount.bind(this),
        toggleFavorite: this.toggleFavorite.bind(this),
        checkFavorite: this.checkFavorite.bind(this),
        updateAvatar: this.updateAvatar.bind(this),
        joinClub: this.joinClub.bind(this),
        checkClubUserCanSubmit: this.checkClubUserCanSubmit.bind(this),
        updateUserData: this.updateUserData.bind(this),
        hasNotification: this.hasNotification.bind(this),
        getNotifications: this.getNotifications.bind(this),
    }

    componentDidUpdate(prevProps) {
        if (prevProps.router.asPath !== this.props.router.asPath) {
            this.setState({ prevRoute: prevProps.router })
            checkUserRedirection({
                lang: this.props.router.locale,
                user: this.state.user,
                ctx: this.props.router,
                redirectAutomatically: true,
            })
        }
    }

    //! Component Did Mount
    componentDidMount() {
        this.getNotifications()
        this.hasNotification()

        this.setState({
            ...this.state,
        })
    }

    hasNotification() {
        return this.state.user && asyncFunctionDecorator(() =>
            api.get(`notification-status`).then((res) =>
                this.setState({
                    ...this.state,
                    ...res,
                })
            )
        )
    }

    getNotifications() {
        return this.state.user && asyncFunctionDecorator(() =>
            api.get(`notifications`).then((res) =>
                this.setState({
                    ...this.state,
                    ...res,
                })
            )
        )
    }

    updateAvatar(data) {
        return asyncFunctionDecorator(() => api.post(`update-avatar`, objectToFormData(data), undefined, 'multipart/form-data'))
    }

    joinClub({ clubId, message }) {
        return asyncFunctionDecorator(() => api.post('join-club', { club_id: clubId, message }).then(() => this.updateUserData()))
    }

    checkClubUserCanSubmit({ clubId }) {
        if (this.state.user) {
            const submittedClubs = this.state.user.submittedClubs
            return !submittedClubs.approved.includes(clubId) && !submittedClubs.pending.includes(clubId) && this.state.user.status === 'approved'
        }
        return false
    }

    checkFavorite(id, type) {
        if (this.state.user) {
            if (type === 'event') {
                return this.state.user.eventFavorites.find((eventId) => eventId === id)
            } else if (type === 'club') {
                return this.state.user.clubFavorites.find((clubId) => clubId === id)
            }
        }
    }

    toggleFavorite(type, id) {
        if (this.state.user) {
            return api.post('toggle-favorite', { type, id }).then(() => this.updateUserData())
        } else {
            return this.handleRouterRedirect(config.routes.signup.path)
        }
    }

    getUserInfo() {
        return asyncFunctionDecorator(() => api.get('account-info'))
    }

    handleFirstStep(data) {
        return asyncFunctionDecorator(() => api.post('fill-profile-step-1', objectToFormData(data), undefined, 'multipart/form-data').then(() => this.updateUserData()))
    }

    handleSecondStep(data) {
        return asyncFunctionDecorator(() => api.post('fill-profile-step-2', data).then(() => this.updateUserData()))
    }

    handleThirdStep(data) {
        return asyncFunctionDecorator(() =>
            api
                .post('fill-profile-step-3', data)
                .then(() => this.props.router.push(config.routes.thankYouMessage.path))
                .then(() => this.updateUserData())
        )
    }

    accountInfo() {
        return this.state.user?.verified && asyncFunctionDecorator(() => api.get('fill-profile-options'))
    }

    login(data) {
        return asyncFunctionDecorator(() =>
            api.post('login', data).then(({ accessToken, currentUser }) => {
                setCookie('accessToken', accessToken)

                this.setState(
                    {
                        isLoggedIn: true,
                        user: currentUser,
                    },
                    () => {
                        this.handleRouterRedirect(this.props.router.query?.r || config.routes.account.path)
                    }
                )
            })
        )
    }

    forgotPassword(data) {
        return asyncFunctionDecorator(() => api.post('forgot-password', data))
    }

    resetPassword(data) {
        return asyncFunctionDecorator(() => api.post('reset-password', data))
    }

    updateAccount(data) {
        return asyncFunctionDecorator(() => api.post('update-account', data).then(() => this.setState({ user: { ...this.state.user, ...data } })))
    }

    removeAccount() {
        return asyncFunctionDecorator(() =>
            api.post('remove-account').then(() => {
                removeCookie('accessToken')

                this.setState(
                    {
                        isLoggedIn: false,
                        user: null,
                    },
                    () => {
                        this.handleRouterRedirect('/')
                    }
                )
            })
        )
    }

    updatePassword(data) {
        return asyncFunctionDecorator(() => api.post('update-password', data))
    }

    register(data) {
        return asyncFunctionDecorator(() =>
            api.post('register', data).then(({ accessToken, currentUser }) => {
                setCookie('accessToken', accessToken)

                this.setState(
                    {
                        isLoggedIn: true,
                        user: currentUser,
                    },
                    () => {
                        this.handleRouterRedirect(`${config.routes.verifyEmailAddress.path}${this.props.router.query?.r ? `?r=${this.props.router.query?.r}` : ''}`)
                    }
                )
            })
        )
    }

    resendVerifyCode(data) {
        return asyncFunctionDecorator(() =>
            api.post(`resend-verify-code`, data).then(() => {
                this.setState({
                    user: {
                        ...this.state.user,
                        ...data
                    }
                })
            })
        )
    }

    verifyAccount(code) {
        return asyncFunctionDecorator(() =>
            api.post(`verify-account`, code).then(() => {
                return this.updateUserData()
            })
        )
    }

    handleRouterRedirect(path) {
        this.props.router.push(path || '/')
    }

    updateUserData(isNewUser) {
        return getUser(this.props.router.locale, this.state, this.props.router, false).then((res) => {
            this.setState({ ...res.newState, newUser: !!isNewUser }, () => {
                res.redirect && this.handleRouterRedirect(res.redirectUrl)
            })
        })
    }

    logout() {
        return asyncFunctionDecorator(() =>
            api.post('logout').then(() => {
                removeCookie('accessToken')

                this.setState(
                    {
                        isLoggedIn: false,
                        user: null,
                    },
                    () => {
                        this.handleRouterRedirect('/')
                    }
                )
            })
        )
    }

    render() {
        return <UserContext.Provider value={{ ...this.state, ...this.methods }}>{this.props.children}</UserContext.Provider>
    }
}

export default withRouter(UserProvider)
export const UserConsumer = UserContext.Consumer
