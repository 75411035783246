import { useState, useCallback, useEffect } from 'react'

//* Style
import RotationRejectionStyle from './style'

//* Components
import Text from 'components/common/Text'

const RotationRejection = () => {
    //! State
    const [landscape, setLandscape] = useState(false)

    useEffect(() => {
        checkOrientation()
    }, [])

    useEffect(() => {
        typeof window !== 'undefined' && window.addEventListener('resize', checkOrientation)

        return () => {
            window.removeEventListener('resize', checkOrientation)
        }
    }, [landscape])

    const checkOrientation = useCallback(() => {
        setLandscape(window.innerWidth <= 840 && window.innerWidth > window.innerHeight)
    }, [landscape])

    return (
        landscape && (
            <RotationRejectionStyle>
                <Text
                    className={'h3 font-reckless-neue-semibold birodo-green rotation-rejection-title'}
                    text={'rotationRejectionTitle'}
                />
                <Text
                    className={'p p2 font-jakarta-sans-semibold birodo-green rotation-rejection-description'}
                    text={'rotationRejectionDescription'}
                />
            </RotationRejectionStyle>
        )
    )
}

export default RotationRejection
