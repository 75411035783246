export default {
    colors: {
        birodoGreen: '#1F4836',
        cryoFreeze: '#DCEBE1',
        sweetAriel: '#E5EAE3',
        maltedMint: '#BED6CA',
        modernMint: '#8AA197',
        clearlyAqua: '#CDE1D4',
        juniperBreeze: '#D9E1D6',
        mandysPink: '#F7B89C',
        fruitRed: '#F7866E',
        white: '#FFFFFF',
        lemonPie: '#EAFA61',
        pureWhite: '#F9F6F2',
        durianSmell: '#E6E1DB',
        obstinateOrange: '#D9512C',
        hypnotism: '#345848',
        jasperPark: '#496458',
        sycamoreGrove: '#6A8579',
        goldenChurro: '#F7CB73',
        naturalOrchestra: '#499F74',
        basilSmash: '#BAE6A0',
        flaxBloom: '#D4D6F1',
        romanticBallad: '#E2BEE1',
        turquoiseTower: '#A4E3CC',
        desirePink: '#F0C6D4',
        garbanzoBean: '#F0D5A2',
        cocoaButter: '#F6F3C1',
        paleOlive: '#D3C7A1',
        cultured: "#F3F5F4",
        successLight: "#F2FFF8"
    },

    mediaQuery: {
        DesktopSizeXL: '2560px',
        DesktopSizeL: '1920px',
        DesktopSizeM: '1510px',
        DesktopSizeS: '1440px',
        DesktopSizeXS: '1280px',
        TabletSize: '1024px',
        TabletSizeS: '768px',
        MobileSize: '601px',
        MobileSizeS: '398px',

        DesktopSizeXLMin: '2559px',
        DesktopSizeLMin: '1919px',
        DesktopSizeMMin: '1509px',
        DesktopSizeSMin: '1439px',
        DesktopSizeXSMin: '1279px',
        TabletSizeMin: '1023px',
        TabletSizeSMin: '767px',
        MobileSizeMin: '600px',
    },
}
