import React from 'react'

const formValidation = (Translate, val, type, msg, settings) => {
    const isLength = val && (typeof val === 'string' ? val.trim().length > 0 : typeof val === 'number' || val.length > 0)

    if (!isLength && type !== 'timepicker' && type !== 'c_password' && type !== 'uploader') {
        return <Translate val={msg || 'fieldIsRequired'} />
    }

    switch (type) {
        case 'birth-date':
            const date = /^(?:0[1-9]|[12]\d|3[01])([\/])(?:0[1-9]|1[012])\1(?:19|20)\d\d$/,
                isBirthDate = date.test(val)

            return isBirthDate || <Translate val={'invalidBirthDate'} />
        case 'email':
            const email = /^(([^<>()\[\]\\.,:\s@"]+(\.[^<>()\[\]\\.,:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                isemail = email.test(String(val.trim()).toLowerCase())
            return isemail || <Translate val={'invalidEmailAddress'} />
        case 'password':
            const pass = /(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}/,
                isPassword = pass.test(val)
            return isPassword || <Translate val={'invalidPassword'} />
        case 'c_password':
            return (val.c_pass && val.pass === val.c_pass) || <Translate val={msg || 'invalidConfirmPassword'} />
        case 'phone':
            const phone = /^[\+]?[0-9-]{9,}$/g,
                isPhone = phone.test(val)
            return isPhone || <Translate val={'invalidPhoneNumber'} />
        case 'number':
            let number = /[0-9]/g,
                isNumber = number.test(val)
            if (settings && isNumber) {
                isNumber = checkMinMax(Translate, settings, val, type)
            }
            return isNumber || <Translate val={'invalidNumber'} />
        case 'checkbox':
            let isChecked = true
            if (settings) {
                isChecked = checkMinMax(Translate, settings, val, type)
            }

            return isChecked || <Translate val={'fieldIsRequired'} />
        case 'uploader':
            let hasFiles = !!val?.length || !!val?.fileList.length

            if (hasFiles && settings && settings.multiple) {
                hasFiles = checkMinMax(Translate, settings, val?.fileList || val, type)
            }

            return hasFiles || <Translate val={'fieldIsRequired'} />
        case 'timepicker':
            let isValid = typeof val === 'object' ? !!(val.min && val.max) : val.length > 0

            return isValid || <Translate val={'fieldIsRequired'} />
        default:
            let isVal = true
            if (settings) {
                isVal = checkMinMax(Translate, settings, val, type)
            }
            return isVal || <Translate val={'fieldIsRequired'} />
    }
}

const checkMinMax = (Translate, settings, val, type) => {
    let value = type === 'number' ? val : typeof val === 'string' ? val.trim().length : val.length
    if ((settings.min || typeof settings.min === 'number') && settings.max) {
        let minMaxText =
            type === 'number'
                ? 'minIsNumberAndMaxIsNumber'
                : type === 'checkbox'
                    ? 'checkMinAndMaxFields'
                    : type === 'tagsInput'
                        ? 'addMinAndMaxElements'
                        : type === 'uploader'
                            ? 'addMinAndMaxImages'
                            : 'typeMinCharsAndTypeMaxChars'
        minMaxText = (
            <Translate
                val={minMaxText}
                vars={{ min: settings.min, max: settings.max }}
            />
        )

        return (value >= settings.min && value <= settings.max) || minMaxText
    } else if ((settings.min || typeof settings.min === 'number') && !settings.max) {
        let minText = type === 'number' ? 'minIsNumber' : type === 'checkbox' ? 'checkMinFields' : type === 'tagsInput' ? 'addMinElements' : type === 'uploader' ? 'addMinImages' : 'typeMinChars'
        minText = (
            <Translate
                val={minText}
                vars={{ min: settings.min }}
            />
        )

        return value >= settings.min || minText
    } else if (settings.max && !settings.min) {
        let maxText = type === 'number' ? 'maxIsNumber' : type === 'checkbox' ? 'checkMaxFields' : type === 'tagsInput' ? 'addMaxElements' : type === 'uploader' ? 'addMaxImages' : 'typeMaxChars'
        maxText = (
            <Translate
                val={maxText}
                vars={{ max: settings.max }}
            />
        )

        return value <= settings.max || maxText
    }
    return false
}

export default formValidation
