//* Components
import Text from 'components/common/Text'
import Icon from 'components/common/Icon'

//* Style
import GlobalErrorStyle from './style'

const GlobalError = ({ error, hasMarginBottom }) => {
    return (
        <GlobalErrorStyle className={`error-wrap ${hasMarginBottom ? 'margin-bottom' : ''}`}>
            <div className={'error-icon-wrap'}>
                <Icon
                    name={'cross'}
                    className={'error-icon'}
                />
            </div>
            <div>
                <Text
                    className={'p p1 font-reckless-neue-medium birodo-green'}
                    text={error}
                />
                <Text
                    className={'p p3 font-jakarta-sans-medium birodo-green error-description'}
                    text={'errorDescription'}
                />
            </div>
        </GlobalErrorStyle>
    )
}

export default GlobalError
