import { useState, useEffect, useCallback, useMemo } from 'react'

//* HOC's
import withDataContext from 'context/consumerHOC/DataConsumer'
import withLanguageContext from 'context/consumerHOC/LanguageConsumer'

//* Helpers
import { config } from 'helpers'

//* Components
import Text from 'components/common/Text'
import Button from 'components/common/Button'
import Cookie from 'js-cookie'

//* Style
import CookiePopupStyle from './style'

const CookiePopup = ({ global, selectedLang }) => {
    //! Global data
    const globalData = useMemo(() => global?.[selectedLang], [global, selectedLang])

    //! States
    const [active, setActive] = useState(false)

    //! Did Mount
    useEffect(() => {
        const cpCookie = Cookie.get('cp-netoworkx')

        if (!cpCookie) {
            setActive(true)
        }
    }, [])

    //! Handle accept click
    const handleAcceptClick = useCallback(() => {
        Cookie.set('cp-netoworkx', true)
        setActive(false)
    }, [])

    return active ? (
        <CookiePopupStyle>
            <Text
                className={'h6 font-jakarta-sans-semibold'}
                text={globalData?.cookie?.title}
            />
            <Text
                className={'p p2 font-jakarta-sans-medium cookie-description'}
                text={globalData?.cookie?.text}
            />
            <div className='buttons-wrap'>
                <Button
                    btnType={'link'}
                    url={config.routes.cookiesPolicy.path}
                    text={'cookieSettings'}
                />
                <Button
                    className={'accept-cookie-button'}
                    text={'accept'}
                    color={'birodoGreen'}
                    backgroundColor={'lemonPie'}
                    onClick={handleAcceptClick}
                />
            </div>
        </CookiePopupStyle>
    ) : null
}

export default withLanguageContext(withDataContext(CookiePopup, ['global']), ['selectedLang'])
