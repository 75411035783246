import { useCallback, useRef, useState, useMemo } from 'react'

//* Components
import FormItem from 'components/common/Form/FormItem'
import FormInput from 'components/common/Form/FormInput'
import Icon from 'components/common/Icon'
import Image from 'components/common/Image'
import Text from 'components/common/Text'

//* Style
import CustomUploadStyle from './style'

const CustomUpload = ({ formRef, autoFinish, avatar, status }) => {
    //! States
    const [file, setFile] = useState()
    const [hasError, setHasError] = useState(false)

    //! Refs
    const imageRef = useRef()

    //! Input change
    const handleInputChange = useCallback(
        (e) => {
            const fileSize = Number((e.currentTarget.files[0]?.size / 1024 / 1024).toFixed(4)) // MB
            const maxFileSize = 10

            if (fileSize >= maxFileSize) {
                setHasError(true)
            } else {
                setHasError(false)
                setFile(URL.createObjectURL(e.target.files[0]))

                formRef.current.setFields([
                    {
                        name: 'avatar',
                        value: e.target.files[0],
                    },
                ])

                autoFinish && formRef.current.submit()
            }
        },
        [formRef]
    )

    const getCorrectUserStatus = useMemo(() => {
        if (status?.text === 'approved') {
            return 'approved'
        } else if (status?.text === 'disapproved') {
            return 'disapproved'
        } else {
            return 'pending'
        }
    }, [status])

    return (
        <>
            <CustomUploadStyle>
                <FormItem
                    name={'avatar'}
                    required={false}
                    className={'hidden'}
                    options={{ noStyle: true }}
                >
                    <FormInput type={'hidden'} />
                </FormItem>

                {status && (
                    <Text
                        className={`p p4 font-jakarta-sans-semibold status ${getCorrectUserStatus}`}
                        text={getCorrectUserStatus}
                    />
                )}
                <div className='upload-wrap'>
                    <label
                        className='upload'
                        htmlFor='file-ip-1'
                    >
                        {!file && !avatar?.src && (
                            <div className='layer-upload'>
                                <Icon
                                    className={'inner-icon'}
                                    name={'upload'}
                                />
                            </div>
                        )}
                        {file ||
                            (avatar?.src && (
                                <div className='layer-change'>
                                    <Icon
                                        className={'inner-icon'}
                                        name={'change'}
                                    />
                                </div>
                            ))}
                        {(file || avatar?.src) && (
                            <Image
                                src={file || `${avatar?.src}`}
                                alt={avatar?.alt || ''}
                                ref={imageRef}
                                className={'uploaded-image'}
                            />
                        )}
                        <Icon
                            className={'upload-icon'}
                            name={'user'}
                        />
                    </label>
                    <input
                        className='upload-input'
                        type='file'
                        id='file-ip-1'
                        accept='image/*'
                        onChange={handleInputChange}
                    />
                </div>
            </CustomUploadStyle>
            {hasError && (
                <Text
                    className={'p p4 font-reckless-neue-medium obstinate-orange avatar-error'}
                    text={'avatarErrorMessage'}
                />
            )}
        </>
    )
}

export default CustomUpload
