import { memo as Memo, useState, useMemo, useCallback, useEffect, useRef } from 'react'
import { useRouter } from 'next/router'

//* Components
import Logo from 'components/common/Logo'
import BottomBarNavigation from './BottomBarNavigation/index'
import CustomLink from 'components/common/CustomLink'
import Button from 'components/common/Button'
import Icon from 'components/common/Icon'
import Text from 'components/common/Text'

//* Helpers
import { config } from 'helpers'

//* HOC's
import { withUserContext, withUIContext } from 'context'

//* Style
import HeaderStyle from './style'

const AppHeader = Memo(({ winWidth, screenSizes, transparentHeader, user, notifications, unreadNotifications, hasNotification, getNotifications }) => {
    //! Router
    const router = useRouter()

    //! Refs
    const defaultScroll = useRef(0)

    //! States
    const [isBurgerMenuActive, setIsBurgerMenuActive] = useState(false)
    const [scrollDirection, setScrollDirection] = useState(true)
    const [transparent, setTransparent] = useState(true)
    const [isInTop, setIsInTop] = useState(true)
    const [isAccountMenuActive, setIsAccountMenuActive] = useState()
    const [isNotificationMenuActive, setIsNotificationMenuActive] = useState(false)

    //! Header navigation function
    const handleNavigation = useCallback(() => {
        if (window.scrollY > defaultScroll.current && window.scrollY > 0) {
            setScrollDirection(false)
        } else if (window.scrollY < defaultScroll.current || window.scrollY <= 0) {
            setScrollDirection(true)
        }

        setTransparent(transparentHeader && window.scrollY <= window.innerHeight * 0.05)
        setIsInTop(window.scrollY <= window.innerHeight * 0.05)

        defaultScroll.current = window.scrollY
    }, [transparentHeader, router.asPath, transparent])

    //! Header navigation
    useEffect(() => {
        handleNavigation()
        window.addEventListener('scroll', handleNavigation)

        return () => {
            window.removeEventListener('scroll', handleNavigation)
        }
    }, [handleNavigation, transparentHeader, router.asPath])

    const getHeaderColor = useMemo(() => {
        const filteredRoutes = Object.values(config.routes).filter((item) => item.headerHasCustomColor)

        return router.pathname === config.routes.thankYouMessage.path ? false : filteredRoutes.some((item) => item.path === router.pathname)
    }, [config, router])

    useEffect(() => {
        router.events.on('routeChangeStart', () => setIsInTop(true))

        return () => {
            router.events.off('routeChangeStart', () => setIsInTop(true))
        }
    }, [router])

    useEffect(() => {
        window.addEventListener('scroll', () => setIsNotificationMenuActive(false))

        return () => {
            window.removeEventListener('scroll', () => setIsNotificationMenuActive(false))
        }
    }, [])

    const storeNotifications = useMemo(() => {
        return notifications?.map((notification, index) => (
            <div
                className={`notify ${notification.status}`}
                key={index}
            >
                <div className='user-icon'>
                    <Icon
                        name={'user'}
                        className={'notification-user'}
                    />
                    <div className='mini-circle'>
                        <Icon
                            className={'mini-circle-icon'}
                            name={notification.status === 'approved' ? 'checkbox' : 'cross'}
                        />
                    </div>
                </div>
                <div className='notify-description'>
                    <Text className={'p p3 font-jakarta-sans-bold message-part'}>{notification.type} </Text>
                    <Text className={'p p3 font-jakarta-sans-medium message-part'}>{notification.message}</Text>
                    <Text className={'p p4 font-jakarta-sans-medium ago modern-mint'}>{notification.date}</Text>
                </div>
            </div>
        ))
    }, [notifications])

    const storeHeaderList = useMemo(() => {
        return Object.values(config.routes).map((item, index) => {
            return (
                item.headerMenu && (
                    <li
                        className='link-wrap'
                        key={index}
                    >
                        <CustomLink
                            className={'p p2 font-jakarta-sans-medium birodo-green list-item'}
                            url={item.path}
                        >
                            {item.name}
                        </CustomLink>
                        <CustomLink
                            className={'p p2 font-jakarta-sans-medium birodo-green fake-item'}
                            url={item.path}
                        >
                            {item.name}
                        </CustomLink>
                    </li>
                )
            )
        })
    }, [config])

    //! Check className
    const checkClass = useCallback((el, cl) => el.classList.contains(cl) || !!el.closest(`.${cl}`), [])

    //! Detect click outside
    const handleClickOutside = useCallback(
        (event) => {
            if (checkClass(event.target, 'notification') && !isNotificationMenuActive) {
                hasNotification()
                getNotifications()
                setIsNotificationMenuActive(true)
            } else if (checkClass(event.target, 'notification') || (!checkClass(event.target, 'notification-menu') && isNotificationMenuActive)) {
                setIsNotificationMenuActive(false)
            }
        },
        [isNotificationMenuActive]
    )

    useEffect(() => {
        hasNotification()
    }, [router])

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside)

        return () => {
            document.removeEventListener('mousedown', handleClickOutside)
        }
    }, [handleClickOutside])

    return (
        <>
            <HeaderStyle
                className={`${isInTop ? 'in-top' : ''} ${transparent ? 'transparent-header' : ''} ${router.pathname == config.routes.thankYouMessage.path ? 'thank-you' : ''} ${scrollDirection ? `visible` : ``
                    } ${router.pathname === '/blog/[slug]' ? 'blog-individual' : ''}`}
            >
                <div className={`header ${getHeaderColor && user?.status !== 'profile_fill_completed' && user?.status !== 'approved' ? 'header-custom-color' : ''}`}>
                    <Logo type={transparent ? 'light' : ''} />
                    {winWidth >= screenSizes.desktopSizeXS && <ul className='header-list'>{storeHeaderList}</ul>}
                    <div className='buttons-wrap'>
                        {!!user ? (
                            <>
                                <div className='notification-wrap'>
                                    <Icon
                                        className={`notification ${unreadNotifications ? 'has-notification' : ''}`}
                                        name={'notification'}
                                    />
                                    {isNotificationMenuActive && (
                                        <div className='notification-menu'>
                                            <Text
                                                className={'p p1 font-jakarta-sans-semibold notification-title'}
                                                text={'notification'}
                                            />
                                            <div className='notifications-wrap'>{storeNotifications}</div>
                                        </div>
                                    )}
                                    <Button
                                        btnType={'link'}
                                        url={config.routes.account.path}
                                        text={'account'}
                                        className={'header-account'}
                                    />
                                </div>
                            </>
                        ) : (
                            <>
                                <Button
                                    btnType={'link'}
                                    url={config.routes.signIn.path}
                                    text={config.routes.signIn.name}
                                    color={'birodoGreen'}
                                    className={'sign-in'}
                                />
                                <Button
                                    url={config.routes.signUp.path}
                                    text={'joinUs'}
                                    color={'birodoGreen'}
                                    backgroundColor={'lemonPie'}
                                />
                            </>
                        )}
                    </div>
                    <div className={`header-fake-background`} />
                </div>
            </HeaderStyle>

            {winWidth < screenSizes.desktopSizeXS && (
                <BottomBarNavigation
                    setIsBurgerMenuActive={setIsBurgerMenuActive}
                    isBurgerMenuActive={isBurgerMenuActive}
                    setIsAccountMenuActive={setIsAccountMenuActive}
                    isAccountMenuActive={isAccountMenuActive}
                />
            )}
        </>
    )
})

export default withUserContext(withUIContext(AppHeader, ['screenSizes', 'winWidth']), ['user', 'notifications', 'unreadNotifications', 'hasNotification', 'getNotifications'])
