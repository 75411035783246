import styled from 'styled-components'

const FooterStyle = styled.footer`
	--firstLinePadding: var(--sp13x) var(--sp16x);
	--footerBoxesBorderRadius: var(--sp4x);
	--footerMarginLeftRightBottom: var(--sp4x);
	--secondLinePadding: var(--sp13x) var(--sp16x) var(--sp5x) var(--sp16x);

	--secondLineLeftSideMaxWidth: var(--sp114x);
	--footerBtnMarginTop: var(--sp9x);
	--listTitleMarginBottom: var(--sp5x);
	--leftSideMarginRight: var(--sp29x);
	--listItemMarginBottom: var(--sp2-5x);

	--copyrightMarginTop: calc(var(--sp17x) + var(--sp0-5x));

	margin: var(--footerMarginLeftRightBottom);
	margin-top: unset;

	.first-line {
		background-color: var(--birodoGreen);
		border-radius: var(--footerBoxesBorderRadius);
		padding: var(--firstLinePadding);

		.footer {
			width: 100%;
			pointer-events: none;
		}
	}

	.second-line {
		display: flex;
		flex-direction: column;
		background-color: var(--birodoGreen);
		padding: var(--secondLinePadding);
		border-radius: var(--footerBoxesBorderRadius);

		.left-right-wrap {
			display: flex;

			.left {
				max-width: var(--secondLineLeftSideMaxWidth);
				width: 100%;
				margin-right: var(--leftSideMarginRight);

				.footer-btn {
					width: fit-content;
					margin-top: var(--footerBtnMarginTop);
				}
			}

			.right {
				display: flex;
				width: calc(100% - var(--secondLineLeftSideMaxWidth) - var(--leftSideMarginRight));

				.list-title {
					margin-bottom: var(--listTitleMarginBottom);
				}

				.company,
				.legal,
				.social {
					width: calc(100% / 3);

					.link-wrap {
						position: relative;
						display: flex;
						flex-direction: column;
						overflow: hidden;
						margin-bottom: var(--listItemMarginBottom);
						width: fit-content;
						cursor: pointer;

						@media (hover: hover) {
							&:hover {
								.list-item {
									top: calc(var(--sp5x) * -1);
								}

								.fake-item {
									top: 0;
								}
							}
						}

						.list-item {
							position: relative;
							top: 0;
							left: 0;
							transition: 0.6s;

							&::first-letter {
								text-transform: uppercase;
							}
						}

						.fake-item {
							position: absolute;
							top: 120%;
							left: 0;
							transition: 0.6s;

							&::first-letter {
								text-transform: uppercase;
							}
						}
					}
				}
			}
		}
	}

	.copyright-wrap {
		display: flex;
		justify-content: space-between;
		margin-top: var(--copyrightMarginTop);

		.copyright-link-wrap {
			display: flex;

			.concept-link {
				margin-left: var(--sp0-5x);
				text-decoration: underline;
			}
		}
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeXLMin}) and (min-width: ${(props) => props.theme.mediaQuery.DesktopSizeL}) {
		--firstLinePadding: var(--sp10x) var(--sp12x);
		--footerBoxesBorderRadius: var(--sp4x);
		--footerMarginLeftRightBottom: var(--sp2-5x);
		--secondLinePadding: var(--sp10x) var(--sp12x) var(--sp4x) var(--sp12x);

		--footerBtnMarginTop: var(--sp7x);
		--secondLineLeftSideMaxWidth: var(--sp86x);
		--listTitleMarginBottom: var(--sp4x);
		--leftSideMarginRight: var(--sp22x);
		--listItemMarginBottom: var(--sp2x);

		--copyrightMarginTop: var(--sp13x);
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeLMin}) and (min-width: ${(props) => props.theme.mediaQuery.DesktopSizeM}) {
		--firstLinePadding: var(--sp8x) var(--sp10x);
		--footerBoxesBorderRadius: var(--sp4x);
		--footerMarginLeftRightBottom: var(--sp2-5x);
		--secondLinePadding: var(--sp8x) var(--sp10x) var(--sp3x) var(--sp10x);

		--secondLineLeftSideMaxWidth: var(--sp64x);
		--footerBtnMarginTop: var(--sp6x);
		--listTitleMarginBottom: var(--sp3x);
		--leftSideMarginRight: var(--sp17x);
		--listItemMarginBottom: var(--sp2x);

		--copyrightMarginTop: var(--sp11x);
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeMMin}) and (min-width: ${(props) => props.theme.mediaQuery.DesktopSizeS}) {
		--firstLinePadding: var(--sp8x) var(--sp10x);
		--footerBoxesBorderRadius: var(--sp4x);
		--footerMarginLeftRightBottom: var(--sp2-5x);
		--secondLinePadding: var(--sp8x) var(--sp10x) var(--sp3x) var(--sp10x);

		--secondLineLeftSideMaxWidth: var(--sp63x);
		--footerBtnMarginTop: var(--sp6x);
		--listTitleMarginBottom: var(--sp3x);
		--leftSideMarginRight: var(--sp16x);
		--listItemMarginBottom: var(--sp2x);

		--copyrightMarginTop: var(--sp11x);
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeSMin}) and (min-width: ${(props) => props.theme.mediaQuery.DesktopSizeXS}) {
		--firstLinePadding: var(--sp7x) var(--sp6x);
		--footerBoxesBorderRadius: var(--sp2-5x);
		--footerMarginLeftRightBottom: var(--sp2-5x);
		--secondLinePadding: var(--sp7x) var(--sp6x) var(--sp3x) var(--sp6x);

		--secondLineLeftSideMaxWidth: var(--sp54x);
		--footerBtnMarginTop: var(--sp5x);
		--listTitleMarginBottom: var(--sp3x);
		--leftSideMarginRight: var(--sp15x);
		--listItemMarginBottom: var(--sp2x);

		--copyrightMarginTop: var(--sp8x);
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeXSMin}) and (min-width: ${(props) => props.theme.mediaQuery.TabletSize}) {
		--firstLinePadding: var(--sp5x);
		--footerBoxesBorderRadius: var(--sp2x);
		--footerMarginLeftRightBottom: var(--sp1-5x);
		--secondLinePadding: var(--sp5x) var(--sp5x) var(--sp3x) var(--sp5x);

		--secondLineLeftSideMaxWidth: var(--sp43x);
		--footerBtnMarginTop: var(--sp5x);
		--listTitleMarginBottom: var(--sp3x);
		--leftSideMarginRight: var(--sp3x);
		--listItemMarginBottom: var(--sp2x);

		--copyrightMarginTop: var(--sp6x);
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.TabletSizeMin}) and (min-width: ${(props) => props.theme.mediaQuery.TabletSizeS}) {
		--firstLinePadding: var(--sp5x);
		--footerBoxesBorderRadius: var(--sp2x);
		--footerMarginLeftRightBottom: var(--sp1-5x);
		--secondLinePadding: var(--sp5x) var(--sp5x) var(--sp3x) var(--sp5x);

		--secondLineLeftSideMaxWidth: 100%;
		--footerBtnMarginTop: var(--sp4x);
		--listTitleMarginBottom: var(--sp2-5x);
		--leftSideMarginRight: 0;
		--listItemMarginBottom: var(--sp2x);

		--copyrightMarginTop: var(--sp6x);

		.left-right-wrap {
			flex-direction: column;

			.right {
				margin-top: var(--sp8x);
			}
		}
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.TabletSizeSMin}) {
		--firstLinePadding: var(--sp5x) var(--sp2x);
		--footerBoxesBorderRadius: var(--sp2x);
		--footerMarginLeftRightBottom: var(--sp1x);
		--secondLinePadding: var(--sp5x) var(--sp2x) var(--sp2x) var(--sp2x);

		--leftSideMarginRight: 0;
		--secondLineLeftSideMaxWidth: 100%;
		--footerBtnMarginTop: var(--sp3x);
		--listTitleMarginBottom: var(--sp2x);
		--listItemMarginBottom: var(--sp1-5x);

		--copyrightMarginTop: calc(var(--sp4x) + var(--sp0-5x));

		.second-line {
			.left-right-wrap {
				flex-direction: column;

				.right {
					margin-top: var(--sp8x);
					flex-wrap: wrap;

					.company,
					.legal,
					.social {
						width: 50%;
					}

					.legal {
						margin-top: var(--sp5x);

						&.no-socials {
							margin-top: unset;
						}
					}
				}
			}
		}
	}
`

export default FooterStyle
