import { Component, createContext } from 'react'
import { withRouter } from 'next/router'

//* Helper's
import { ThemeProvider } from 'styled-components'
import themes from 'styles/theme'
import HelperClass from 'styles/helperClass'
import Variables from 'styles/variables'
import Typography from 'styles/typography'

//* Style
import FormStyle from 'styles/pages/FormStyle'

const UIContext = createContext(null)

class UIProvider extends Component {
    //! States
    state = {
        winWidth: 1920,
        winHeight: 1080,
        screenSizes: {
            desktopSizeL: 1920,
            desktopSizeM: 1510,
            desktopSizeS: 1440,
            desktopSizeXL: 2560,
            desktopSizeXS: 1280,
            tabletSize: 1024,
            tabletSizeS: 768,
            mobileSize: 601,
        },
        preloader: true,
        highComponent: null,
        popupLoading: false,
        popupIsOpen: false,
        popupComponent: null,
        popupProps: {},
        bodyColorAccount: null,
    }

    //! Methods
    methods = {
        addHighComponent: this.addHighComponent.bind(this),
        removeHighComponent: this.removeHighComponent.bind(this),
        disableDomScroll: this.disableDomScroll.bind(this),
        enableDomScroll: this.enableDomScroll.bind(this),
        openPopup: this.openPopup.bind(this),
        closePopup: this.closePopup.bind(this),
        togglePopup: this.togglePopup.bind(this),
        togglePopupLoading: this.togglePopupLoading.bind(this),
        setBodyColor: this.setBodyColor.bind(this),
    }

    //! Toggle Loading
    togglePopupLoading(callbackFunc) {
        this.setState({ popupLoading: !this.state.popupLoading }, () => callbackFunc && callbackFunc())
    }

    //! Screen Resize
    screenResize = () => {
        this.setState({
            winWidth: window.innerWidth,
            winHeight: window.innerHeight,
        })
    }

    //! Get Media Screen Sizes from Css Variables
    getScreenSizes() {
        const root = getComputedStyle(document.documentElement)

        const screenSizes = {
            desktopSizeXL: parseInt(root.getPropertyValue('--desktopSizeXL')),
            desktopSizeL: parseInt(root.getPropertyValue('--desktopSizeL')),
            desktopSizeM: parseInt(root.getPropertyValue('--desktopSizeM')),
            desktopSizeS: parseInt(root.getPropertyValue('--desktopSizeS')),
            desktopSizeXS: parseInt(root.getPropertyValue('--desktopSizeXS')),
            tabletSize: parseInt(root.getPropertyValue('--tabletSize')),
            tabletSizeS: parseInt(root.getPropertyValue('--tabletSizeS')),
            mobileSize: parseInt(root.getPropertyValue('--mobileSize')),
        }

        this.setState({ screenSizes })
    }

    //! Open Popup
    openPopup(popupComponent = null, popupProps = {}) {
        // if (typeof popupComponent === 'string' && this.popup.includes(popupComponent) && this.props.router.query?.c !== popupComponent) {
        // 	const { pathname, query } = routerToPathAndQuery(this.props.router, 'c', popupComponent)
        // 	this.props.router.push({ pathname, query }, undefined, { shallow: true })
        // }

        // this.disableDomScroll()

        this.setState({
            popupIsOpen: true,
            popupComponent,
            popupProps,
        })
    }

    //! Close Popup
    closePopup(scroll = false) {
        // const { pathname, query } = routerToPathAndQuery(this.props.router, 'c')
        // this.props.router.push({ pathname, query }, undefined, { scroll: false })

        if (!this.state.popupLoading) {
            this.enableDomScroll()

            this.setState({
                popupIsOpen: false,
                popupComponent: null,
            })
        }
    }

    //! Toggle Popup
    togglePopup() {
        this.setState({
            popupIsOpen: !this.state.popupIsOpen,
        })
    }

    //! Disable DOM Scroll
    disableDomScroll() {
        //? document.querySelector('html').classList.add('hide-scroll')

        const scrollTop = window.pageYOffset || document.documentElement.scrollTop
        const scrollLeft = window.pageXOffset || document.documentElement.scrollLeft

        //? if any scroll is attempted, set this to the previous value
        window.onscroll = function (e) {
            e.preventDefault()
            window.scrollTo(scrollLeft, scrollTop)
        }
    }

    //! Enable DOM Scroll
    enableDomScroll() {
        //? document.querySelector('html').classList.remove('hide-scroll');

        const scrollTop = window.pageYOffset || document.documentElement.scrollTop
        const scrollLeft = window.pageXOffset || document.documentElement.scrollLeft

        window.onscroll = function () { }
        window.scrollTo(scrollLeft, scrollTop)
    }

    //! Component Did Mount
    componentDidMount() {
        this.setState({
            ...this.state,
        })

        window.addEventListener('resize', this.screenResize)
        this.getScreenSizes()
        this.screenResize()
    }

    componentDidUpdate(prevProps) {
        if (prevProps.router.asPath !== this.props.router.asPath) {
            this.setState({
                ...this.state,
            })
        }
    }

    //! Component Will Unmount
    componentWillUnmount() {
        window.addEventListener('resize', this.screenResize)
        document.removeEventListener('keydown', this.escKeydown, false)
    }

    //! Add High Component
    addHighComponent(component = null) {
        this.setState({
            highComponent: component,
        })
    }

    updateHighComponentProps(props) {
        const Comp = this.state.component
        Comp.props = props

        this.setState({
            highComponent: Comp,
        })
    }

    //! Remove High Component
    removeHighComponent() {
        this.setState({
            highComponent: null,
        })
    }

    setBodyColor(color) {
        this.setState({ bodyColorAccount: color })
    }

    render() {
        return (
            <UIContext.Provider value={{ ...this.state, ...this.methods }}>
                <ThemeProvider theme={themes}>
                    <Variables />
                    <Typography
                        is404={this.props.router.pathname === '/404' ? true : false}
                        popupIsOpen={this.state.popupIsOpen}
                        bodyColor={this.state.bodyColorAccount || this.props.currentPage.bodyColor}
                        isSubscribePage={this.props.router.pathname.includes('subscribe')}
                    />
                    <HelperClass />
                    <FormStyle />

                    {this.props.children}
                </ThemeProvider>
            </UIContext.Provider>
        )
    }
}

export default withRouter(UIProvider)
export const UIConsumer = UIContext.Consumer
