import { memo as Memo, useMemo } from 'react'

//* HOC's
import { withLanguageContext } from 'context'

//* Component's
import Icon from 'components/common/Icon'
import CustomLink from 'components/common/CustomLink'

//* Style
import ButtonStyle from './style'

const Button = Memo(
	({
		btnType,
		text,
		className,
		onClick,
		children,
		url,
		customLinkProps,
		translate,
		target,
		type,
		backgroundColor,
		color,
		size,
		hasBorder,
		isVertical,
		iconName,
		iconColor,
		isFavorite,
		isBottomBar,
		borderColor,
		borderSmall,
		disable,
	}) => {
		const Component = useMemo(() => (url ? CustomLink : 'button'), [url])
		const customProps = useMemo(() => (url ? { ...customLinkProps, url, target } : { type }), [url])

		let button

		switch (btnType) {
			case 'link':
				button = (
					<ButtonStyle
						className={`${btnType} ${className} ${disable ? 'disable' : ''}`}
						onClick={onClick}
						color={color}
					>
						<Component {...customProps}>
							<div className='content-wrap'>
								<span className='real-text'>{children || translate(text)}</span>
								<span className='fake-text'>{children || translate(text)}</span>
							</div>
						</Component>
					</ButtonStyle>
				)
				break
			case 'icon':
				button = (
					<ButtonStyle
						className={`${btnType} ${className} ${isBottomBar ? 'bottom-bar' : ''} ${disable ? 'disable' : ''}`}
						onClick={onClick}
						color={color}
						backgroundColor={backgroundColor}
						iconColor={iconColor}
					>
						<Component
							aria-label={iconName}
							{...customProps}
						>
							{children || (
								<Icon
									name={iconName}
									className={`icon`}
								/>
							)}
						</Component>
					</ButtonStyle>
				)
				break
			case 'social':
				button = (
					<ButtonStyle
						className={`${btnType} ${className} ${disable ? 'disable' : ''}`}
						onClick={onClick}
						color={color}
						iconColor={iconColor}
					>
						<Component {...customProps}>
							<span className='text-content uppercase font-jakarta-sans-bold p p1'>{children || translate(text)}</span>
						</Component>
					</ButtonStyle>
				)
				break
			case 'favorite':
				button = (
					<ButtonStyle
						className={`${btnType} ${className} ${size} ${disable ? 'disable' : ''}`}
						onClick={onClick}
						color={color}
						iconColor={iconColor}
					>
						<Component
							aria-label='Favorite icon'
							{...customProps}
						>
							{children || (
								<Icon
									name={`${isFavorite ? 'heart-filled' : 'heart'}`}
									className={`icon`}
								/>
							)}
						</Component>
					</ButtonStyle>
				)
				break
			default:
				button = (
					<ButtonStyle
						backgroundColor={backgroundColor}
						color={color}
						borderColor={borderColor}
						className={`${btnType} ${className} ${size} ${hasBorder ? 'border' : ''} ${isVertical ? 'vertical' : ''} ${borderSmall ? 'small-border' : ''} ${disable ? 'disable' : ''}`}
						onClick={onClick}
					>
						<Component {...customProps}>
							<div className='content-wrap'>
								<span className='real-text'>{children || translate(text)}</span>
								<span className='fake-text'>{children || translate(text)}</span>
							</div>
						</Component>
					</ButtonStyle>
				)
		}

		return button
	}
)

Button.defaultProps = {
	className: '',
	backgroundColor: 'birodoGreen',
	color: '',
	btnType: '',
	size: 'small',
	hasBorder: false,
	vertical: false,
	isBottomBar: false,
	iconName: '',
	iconColor: 'white',
	borderColor: '',
	borderSmall: false,
	disable: false,
}

export default withLanguageContext(Button, ['translate'])
