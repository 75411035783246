//* Provider's
import LanguageProvider from './providers/LanguageProvider'
import UIProvider from './providers/UIProvider'
import DataProvider from './providers/DataProvider'
import UserProvider from './providers/UserProvider'

//* ConsumerHOC's
import withLanguageContext from './consumerHOC/LanguageConsumer'
import withUIContext from './consumerHOC/UIConsumer'
import withDataContext from './consumerHOC/DataConsumer'
import withUserContext from './consumerHOC/UserConsumer'

export { LanguageProvider, withLanguageContext, UIProvider, withUIContext, DataProvider, withDataContext, UserProvider, withUserContext }
