import styled from 'styled-components'

const PopupStyle = styled.div`
	--popupMaxHeight: 100vh;
	--popupContentMaxHeight: 100%;
	--popupContentPadding: 0;
	--popupContentBorderRadius: var(--sp4x);
	--closeSize: var(--sp4x);
	--closePosTopSize: var(--sp5x);
	--closePosRightSize: var(--sp5x);

	--jcMaxWidth: 42.96vw;
	--jcPadding: var(--sp12x) var(--sp24x);

	position: fixed;
	width: 100vw;
	inset: 0;
	z-index: 100000;

	.inner-child {
		width: var(--jcMaxWidth);
		padding: var(--jcPadding);
	}

	&.popup-loading {
		.popup-background {
			animation: loading 1.5s infinite;
			z-index: 3;
		}
	}

	&::-webkit-scrollbar {
		display: none;
	}

	.popup-background {
		background-color: var(--birodoGreen);
		opacity: 50%;
		pointer-events: all;
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
	}

	.popup-content-container {
		max-height: var(--popupMaxHeight);
		display: flex;
		align-items: center;
		width: 100%;
		height: 100%;
		pointer-events: all;
		overflow: scroll;

		&::-webkit-scrollbar {
			display: none;
		}

		.popup-content-wrap {
			position: relative;
			height: fit-content;
			max-width: var(--popupMaxWidth);
			max-height: var(--popupContentMaxHeight);
			padding: var(--popupContentPadding);
			background: var(--cryoFreeze);
			border-radius: var(--popupContentBorderRadius);
			opacity: 1;
			overflow-x: hidden;
			overflow-y: scroll;
			-ms-overflow-style: none;
			scrollbar-width: none;
			margin: auto;

			&::-webkit-scrollbar {
				display: none;
			}

			.close-wrap {
				display: flex;
				align-items: center;
				justify-content: center;
				position: absolute;
				width: var(--closeSize);
				height: var(--closeSize);
				top: var(--closePosTopSize);
				right: var(--closePosRightSize);
				z-index: 2;
				transition: opacity var(--trTime);

				i {
					font-size: var(--closeSize);
					color: var(--birodoGreen);
					cursor: pointer;
				}

				@media (hover: hover) {
					&:hover {
						opacity: 0.8;
					}
				}
			}
		}
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeXLMin}) and (min-width: ${(props) => props.theme.mediaQuery.DesktopSizeL}) {
		--popupContentBorderRadius: var(--sp4x);
		--closeSize: var(--sp3x);
		--closePosTopSize: var(--sp5x);
		--closePosRightSize: var(--sp5x);
		--jcMaxWidth: 43.333vw;
		--jcPadding: var(--sp9x) var(--sp18x);
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeLMin}) and (min-width: ${(props) => props.theme.mediaQuery.DesktopSizeM}) {
		--popupContentBorderRadius: var(--sp2-5x);
		--closeSize: var(--sp2x);
		--closePosTopSize: var(--sp4x);
		--closePosRightSize: var(--sp4x);
		--jcMaxWidth: 42.51vw;
		--jcPadding: var(--sp8x) var(--sp14x);
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeMMin}) and (min-width: ${(props) => props.theme.mediaQuery.DesktopSizeS}) {
		--popupContentBorderRadius: var(--sp2-5x);
		--closeSize: var(--sp2x);
		--closePosTopSize: var(--sp4x);
		--closePosRightSize: var(--sp4x);
		--jcMaxWidth: 42.5vw;
        --jcPadding: var(--sp8x) var(--sp12x);
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeSMin}) and (min-width: ${(props) => props.theme.mediaQuery.DesktopSizeXS}) {
		--popupContentBorderRadius: var(--sp2-5x);
		--closeSize: var(--sp2x);
		--closePosTopSize: var(--sp4x);
		--closePosRightSize: var(--sp4x);
        --jcMaxWidth: 44.06vw;
        --jcPadding: var(--sp8x) var(--sp12x);
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.DesktopSizeXSMin}) and (min-width: ${(props) => props.theme.mediaQuery.TabletSize}) {
		--popupContentBorderRadius: var(--sp2x);
		--closeSize: var(--sp2x);
		--closePosTopSize: var(--sp3x);
		--closePosRightSize: var(--sp3x);
		--jcMaxWidth: 45.7vw;
        --jcPadding: var(--sp7x) var(--sp10x);
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.TabletSizeMin}) and (min-width: ${(props) => props.theme.mediaQuery.TabletSizeS}) {
		--popupContentBorderRadius: var(--sp2x);
		--closeSize: var(--sp2x);
		--closePosTopSize: var(--sp3x);
		--closePosRightSize: var(--sp3x);
		--jcMaxWidth: 61.45vw;
        --jcPadding: var(--sp7x) var(--sp10x);
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.TabletSizeSMin}) {
		--popupContentBorderRadius: var(--sp1-5x);
		--closeSize: var(--sp2x);
		--closePosTopSize: var(--sp3x);
		--closePosRightSize: var(--sp3x);
		--jcMaxWidth: calc(100vw - 2 * var(--contPaddingLR));
        --jcPadding: var(--sp6x) var(--sp3x);
	}
`
export default PopupStyle
