export default {
    routes: {
        home: {
            path: '/',
            slug: 'home',
            name: 'home',
            autoload: true,
            headerMenu: false,
            footerMenuLeft: false,
            transparentHeader: true,
            headerHasCustomColor: false,
            bodyColor: 'var(--pureWhite)',
        },

        awardIndividual: {
            path: '/awards/:slug',
            slug: 'awards',
            name: 'awards',
            singleType: 'singleAward',
            autoload: true,
            headerMenu: false,
            footerMenuLeft: false,
            transparentHeader: true,
            headerHasCustomColor: false,
            bodyColor: 'var(--pureWhite)',
        },

        aboutUs: {
            path: '/about-us',
            slug: 'about-us',
            name: 'About us',
            autoload: true,
            headerMenu: true,
            footerMenuLeft: true,
            transparentHeader: true,
            headerHasCustomColor: false,
            bodyColor: 'var(--pureWhite)',
        },

        whatWeOffer: {
            path: '/what-we-offer',
            slug: 'what-we-offer',
            name: 'What we offer',
            autoload: true,
            headerMenu: true,
            footerMenuLeft: true,
            transparentHeader: false,
            headerHasCustomColor: false,
            bodyColor: 'var(--pureWhite)',
        },

        clubs: {
            path: '/clubs',
            slug: 'club',
            name: 'Clubs',
            postType: {
                slug: 'clubs',
                name: 'clubs',
                queryParamKeys: ['cats', 'locations', 'sortBy', 'page'],
            },
            autoload: true,
            headerMenu: true,
            footerMenuLeft: true,
            transparentHeader: false,
            headerHasCustomColor: false,
            bodyColor: 'var(--pureWhite)',
        },

        clubIndividual: {
            path: '/clubs/:slug',
            slug: 'clubs',
            name: 'clubs',
            singleType: 'club',
            autoload: true,
            headerMenu: false,
            footerMenuLeft: false,
            transparentHeader: false,
            headerHasCustomColor: false,
            bodyColor: 'var(--pureWhite)',
        },

        events: {
            path: '/events',
            slug: 'event',
            name: 'events',
            postType: {
                slug: 'events',
                name: 'events',
                queryParamKeys: ['cats', 'locations', 'sortBy', 'date', 'page'],
            },
            autoload: true,
            headerMenu: true,
            footerMenuLeft: true,
            transparentHeader: false,
            headerHasCustomColor: false,
            bodyColor: 'var(--pureWhite)',
        },

        eventIndividual: {
            path: '/events/:slug',
            slug: 'events',
            name: 'events',
            singleType: 'event',
            autoload: true,
            headerMenu: false,
            footerMenuLeft: false,
            transparentHeader: false,
            headerHasCustomColor: false,
            bodyColor: 'var(--pureWhite)',
        },

        contactUs: {
            path: '/contact-us',
            slug: 'contact-us',
            name: 'Contact us',
            autoload: true,
            headerMenu: true,
            footerMenuLeft: true,
            transparentHeader: true,
            headerHasCustomColor: false,
            bodyColor: 'var(--pureWhite)',
        },

        signIn: {
            path: '/sign-in',
            slug: 'sign-in',
            name: 'Sign In',
            autoload: false,
            headerMenu: false,
            footerMenuLeft: false,
            transparentHeader: false,
            headerHasCustomColor: true,
            bodyColor: 'var(--sweetAriel)',
        },

        privacyPolicy: {
            path: '/privacy-policy',
            slug: 'privacy-policy',
            name: 'Privacy policy',
            autoload: true,
            headerMenu: false,
            footerMenuLeft: false,
            footerMenuRight: true,
            transparentHeader: false,
            headerHasCustomColor: false,
            bodyColor: 'var(--pureWhite)',
        },

        cookiesPolicy: {
            path: '/cookies-policy',
            slug: 'cookies',
            name: 'Cookies policy',
            autoload: true,
            headerMenu: false,
            footerMenuLeft: false,
            footerMenuRight: true,
            transparentHeader: false,
            headerHasCustomColor: false,
            bodyColor: 'var(--pureWhite)',
        },

        termsAndCondition: {
            path: '/terms-and-conditions',
            slug: 'terms',
            name: 'Terms and conditions',
            autoload: true,
            headerMenu: false,
            footerMenuLeft: false,
            footerMenuRight: true,
            transparentHeader: false,
            headerHasCustomColor: false,
            bodyColor: 'var(--pureWhite)',
        },

        dataProtection: {
            path: '/data-protection',
            slug: 'data-protection',
            name: 'Data protection',
            autoload: true,
            headerMenu: false,
            footerMenuLeft: false,
            footerMenuRight: true,
            transparentHeader: false,
            headerHasCustomColor: false,
            bodyColor: 'var(--pureWhite)',
        },

        imprint: {
            path: '/imprint',
            slug: 'imprint',
            name: 'Imprint',
            autoload: true,
            headerMenu: false,
            footerMenuLeft: false,
            footerMenuRight: true,
            transparentHeader: false,
            headerHasCustomColor: false,
            bodyColor: 'var(--pureWhite)',
        },

        faq: {
            path: '/faq',
            slug: 'faq',
            name: 'FAQ',
            autoload: true,
            headerMenu: false,
            footerMenuLeft: false,
            footerMenuRight: true,
            transparentHeader: false,
            headerHasCustomColor: false,
            bodyColor: 'var(--pureWhite)',
        },

        signUp: {
            path: '/sign-up',
            slug: 'sign-up',
            name: 'sign-up',
            autoload: false,
            headerMenu: false,
            footerMenuLeft: false,
            footerMenuRight: false,
            transparentHeader: false,
            headerHasCustomColor: true,
            bodyColor: 'var(--sweetAriel)',
        },

        verifyEmailAddress: {
            path: '/verify-account',
            slug: 'verify-account',
            name: 'verify-account',
            autoload: false,
            headerMenu: false,
            footerMenuLeft: false,
            footerMenuRight: false,
            transparentHeader: false,
            headerHasCustomColor: true,
            bodyColor: 'var(--sweetAriel)',
        },

        account: {
            path: '/account',
            slug: 'account-dashboard',
            name: 'account-dashboard',
            autoload: true,
            headerMenu: false,
            footerMenuLeft: false,
            footerMenuRight: false,
            transparentHeader: false,
            headerHasCustomColor: true,
            private: true,
            always: true,
            bodyColor: 'var(--pureWhite)',
        },

        thankYouMessage: {
            path: '/thank-you-message',
            slug: 'thank-you-message',
            name: 'thank-you-message',
            autoload: false,
            headerMenu: false,
            footerMenuLeft: false,
            footerMenuRight: false,
            transparentHeader: false,
            headerHasCustomColor: true,
            bodyColor: 'var(--sweetAriel)',
        },

        resetPassword: {
            path: '/reset-password',
            slug: 'reset-password',
            name: 'reset-password',
            autoload: false,
            headerMenu: false,
            footerMenuLeft: false,
            footerMenuRight: false,
            transparentHeader: false,
            headerHasCustomColor: true,
            bodyColor: 'var(--sweetAriel)',
        },

        forgotPassword: {
            path: '/forgot-password',
            slug: 'forgot-password',
            name: 'forgot-password',
            autoload: false,
            headerMenu: false,
            footerMenuLeft: false,
            footerMenuRight: false,
            transparentHeader: false,
            headerHasCustomColor: true,
            bodyColor: 'var(--sweetAriel)',
        },

        clubApplication: {
            path: '/club-application',
            slug: 'club-applicant',
            name: 'club-applicant',
            autoload: true,
            headerMenu: false,
            footerMenuLeft: false,
            footerMenuRight: false,
            transparentHeader: false,
            headerHasCustomColor: false,
            bodyColor: 'var(--pureWhite)',
        },

        recommendedClubs: {
            path: '/account/recommended-clubs',
            slug: 'recommended-clubs',
            name: 'recommended-clubs',
            autoload: false,
            headerMenu: false,
            footerMenuLeft: false,
            footerMenuRight: false,
            transparentHeader: false,
            headerHasCustomColor: false,
            bodyColor: 'var(--pureWhite)',
            postType: {
                slug: 'account-clubs/recommended',
                name: 'recommendedClubs',
                queryParamKeys: ['page'],
                always: true,
            },
        },

        myClubs: {
            path: '/account/my-clubs',
            slug: 'my-clubs',
            name: 'my-clubs',
            autoload: false,
            headerMenu: false,
            footerMenuLeft: false,
            footerMenuRight: false,
            transparentHeader: false,
            headerHasCustomColor: false,
            bodyColor: 'var(--pureWhite)',
            postType: {
                slug: 'account-clubs/approved',
                name: 'myClubs',
                queryParamKeys: ['page'],
                always: true,
            },
        },

        pendingClubs: {
            path: '/account/pending-clubs',
            slug: 'pending-clubs',
            name: 'pending-clubs',
            autoload: false,
            headerMenu: false,
            footerMenuLeft: false,
            footerMenuRight: false,
            transparentHeader: false,
            headerHasCustomColor: false,
            bodyColor: 'var(--pureWhite)',
            postType: {
                slug: 'account-clubs/pending',
                name: 'pendingClubs',
                queryParamKeys: ['page'],
                always: true,
            },
        },

        declinedClubs: {
            path: '/account/declined-clubs',
            slug: 'declined-clubs',
            name: 'declined-clubs',
            autoload: false,
            headerMenu: false,
            footerMenuLeft: false,
            footerMenuRight: false,
            transparentHeader: false,
            headerHasCustomColor: false,
            bodyColor: 'var(--pureWhite)',
            postType: {
                slug: 'account-clubs/disapproved',
                name: 'declinedClubs',
                queryParamKeys: ['page'],
                always: true,
            },
        },

        savedClubs: {
            path: '/account/saved-clubs',
            slug: 'saved-clubs',
            name: 'saved-clubs',
            autoload: false,
            headerMenu: false,
            footerMenuLeft: false,
            footerMenuRight: false,
            transparentHeader: false,
            headerHasCustomColor: false,
            bodyColor: 'var(--pureWhite)',
            postType: {
                slug: 'account-clubs/saved',
                name: 'savedClubs',
                queryParamKeys: ['page'],
                always: true,
            },
        },

        savedEvents: {
            path: '/account/saved-events',
            slug: 'saved-events',
            name: 'saved-events',
            autoload: false,
            headerMenu: false,
            footerMenuLeft: false,
            footerMenuRight: false,
            transparentHeader: false,
            headerHasCustomColor: false,
            bodyColor: 'var(--pureWhite)',
            postType: {
                slug: 'saved-events',
                name: 'savedEvents',
                queryParamKeys: ['page'],
                always: true,
            },
        },

        accountSettings: {
            path: '/account/settings',
            slug: 'settings',
            name: 'settings',
            autoload: false,
            headerMenu: false,
            footerMenuLeft: false,
            footerMenuRight: false,
            transparentHeader: false,
            headerHasCustomColor: false,
            bodyColor: 'var(--pureWhite)',
        },

        passwordSettings: {
            path: '/account/password-settings',
            slug: 'password-settings',
            name: 'password-settings',
            autoload: false,
            headerMenu: false,
            footerMenuLeft: false,
            footerMenuRight: false,
            transparentHeader: false,
            headerHasCustomColor: false,
            bodyColor: 'var(--pureWhite)',
        },

        blog: {
            path: '/blog',
            slug: 'blog',
            name: 'blog',
            autoload: true,
            headerMenu: true,
            footerMenuLeft: true,
            footerMenuRight: false,
            transparentHeader: false,
            headerHasCustomColor: false,
            bodyColor: 'var(--pureWhite)',
            postType: {
                slug: 'posts',
                name: 'posts',
                queryParamKeys: ['cats', 'sortBy', 'page'],
            },
        },

        blogIndividual: {
            path: '/blog/:slug',
            slug: 'posts',
            name: 'blog',
            singleType: 'blog',
            autoload: true,
            headerMenu: false,
            footerMenuLeft: false,
            transparentHeader: false,
            headerHasCustomColor: false,
            bodyColor: 'var(--pureWhite)',
        },

        press: {
            path: '/press',
            slug: 'press',
            name: 'Press',
            autoload: true,
            headerMenu: true,
            footerMenuLeft: false,
            transparentHeader: true,
            headerHasCustomColor: false,
            bodyColor: 'var(--pureWhite)',
        },

        columnist: {
            path: '/columnist',
            slug: 'post-application',
            name: 'columnist',
            autoload: true,
            headerMenu: false,
            footerMenuLeft: false,
            transparentHeader: false,
            headerHasCustomColor: false,
            bodyColor: 'var(--pureWhite)',
        },
    },
}
