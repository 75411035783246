import { memo as Memo } from 'react'

//* Component's
import CustomLink from 'components/common/CustomLink'
import Image from 'components/common/Image'

//* Style
import LogoStyle from './style'

const Logo = Memo(({ className, type }) => {
    return (
        <LogoStyle className={`${className || ''}`}>
            <CustomLink
                content={
                    <Image
                        src={type === 'footer' ? '/logo-footer.svg' : type === 'light' ? '/logoLight.svg' : '/logo.svg'}
                        alt={`Logo`}
                    />
                }
                url={'/'}
            />
        </LogoStyle>
    )
})

export default Logo
